import { capitalize } from "@enerbit/base";
import type { ICommunications, IIncomingDataItem } from "../../interfaces";

export const generateCommunications = (
    communications: ICommunications | null,
): IIncomingDataItem[] => {
    if (!communications) return [];

    const items: IIncomingDataItem[] = communications.signal_history.flatMap(
        (signal) => {
            const signalOperator = signal.operator;
            const is_required_antenna = signal.is_required_antenna;
            const requires_ducting_antenna_extension =
                signal.requires_ducting_antenna_extension;

            const signalItems: IIncomingDataItem[] = [
                {
                    field: (
                        <b>
                            <i>Operador</i>
                        </b>
                    ),
                    value: (
                        <b>
                            <i>{capitalize(signalOperator)}</i>
                        </b>
                    ),
                    description: "Operador",
                },
                {
                    field: "Requiere extension para antena",
                    value: is_required_antenna ? "Si" : "No",
                    description: "Extensión para antena",
                },
                {
                    field: "Requiere ductos para la extension de antena",
                    value: requires_ducting_antenna_extension ? "Si" : "No",
                    description: "Ductos para la extension de la antena",
                },
                {
                    field: "Observaciones",
                    value: signal.observations ?? "",
                    description: `Observaciones de ${signalOperator}`,
                },
                ...signal.signal_list.map((s, i) => ({
                    field: `Señal ${i + 1}`,
                    value: s.signal_status,
                    description: `Señal ${signalOperator} ${i + 1}`,
                })),
            ];

            // Filtrado de items vacíos
            return signalItems.filter((item) => item.value !== "");
        },
    );

    return items;
};
