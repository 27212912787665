import type { IConceptItem, IObservations } from "../../interfaces";

export const generateConcept = (
    observations?: IObservations,
): IConceptItem[] => {
    if (!observations) return [];

    return [
        {
            field: "Para el cliente",
            observations:
                observations.for_customer || "No se crearon comentarios.",
        },
        {
            field: "Para nosotros",
            observations:
                observations.for_us.other || "No se crearon comentarios.",
        },
        ...(observations.for_us.backup_plant
            ? [
                  {
                      field: "Mecanismo de suspensión",
                      observations:
                          observations.for_us.suspension_mechanism ?? "No",
                  },
              ]
            : []),
        ...(observations.for_us.backup_plant
            ? [
                  {
                      field: "Planta de respaldo",
                      observations: observations.for_us.backup_plant,
                  },
              ]
            : []),
        {
            field: "¿Este sistema de medida corresponde a una zona común?",
            observations: observations.for_us
                .metering_system_corresponds_to_common_area
                ? "Si."
                : "No.",
        },
        {
            field: "¿Este servicio es utilizado con algún fin comercial?",
            observations: observations.for_us.service_used_commercial_purpose
                ? "Si."
                : "No.",
        },
        {
            field: "¿Requiere apertura para la siguiente visita de instalación o normalización?",
            observations: observations.for_us
                .require_opening_next_installation_normalization
                ? "Si."
                : "No.",
        },
        ...(observations.for_us.how_long_customer_would_remain_without_power
            ? [
                  {
                      field: "¿Cuánto tiempo el cliente permanecería sin energía?",
                      observations:
                          observations.for_us
                              .how_long_customer_would_remain_without_power,
                  },
              ]
            : []),
        {
            field: "¿Requiere planta eléctrica para la ejecución de las actividades?",
            observations: observations.for_us
                .require_electrical_plant_execution_activities
                ? "Si."
                : "No.",
        },
        {
            field: "¿Se requiere equipo de trabajo o herramienta especial para la siguiente visita de instalación o normalización?",
            observations: observations.for_us
                .special_equipment_tools_required_next_installation_normalization
                ? "Si."
                : "No.",
        },
        ...(observations.for_us.requirements_specification
            ? [
                  {
                      field: "Especificación de requisitos",
                      observations:
                          observations.for_us.requirements_specification ||
                          "No.",
                  },
              ]
            : []),
    ];
};
