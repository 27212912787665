import { z } from "zod";

export const EditableSolarFormData = z.object({
    sfv_information: z
        .object({
            inverter_type: z.string().min(1, "Este campo es obligatorio."),
            installation_type: z.string().min(1, "Este campo es obligatorio."),
            epecista: z.string().min(1, "Este campo es obligatorio."),
            kwp_capacity_sfv: z
                .number()
                .refine((val) => val !== null && val !== undefined, {
                    message: "Este campo es obligatorio.",
                }),
        })
        .nullable(),
    access_installation: z.object({
        smartbit_meter_integration: z
            .string()
            .min(1, "Este campo es obligatorio."),
        de_energization_energy_network: z
            .string()
            .min(1, "Este campo es obligatorio."),
        de_energization_energy_inverter: z
            .string()
            .min(1, "Este campo es obligatorio."),
    }),
    features_solar_meter: z.object({
        meter_location: z.string().min(1, "Este campo es obligatorio."),
        meter_high: z.number().min(1, "Este campo es obligatorio."),
        measurement_type: z.string().min(1, "Este campo es obligatorio."),
        meter_size: z.string().min(1, "Este campo es obligatorio."),
        number_of_phases: z.string().min(1, "Este campo es obligatorio."),
        number_meter_element: z.string().min(1, "Este campo es obligatorio."),
        number_connection_elements: z
            .string()
            .min(1, "Este campo es obligatorio."),
    }),
});
