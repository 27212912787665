import moment from "moment";
import type { IField, IIncomingDataItem, ILeadItem } from "../../interfaces";

export const generateHeader = (
    lead: ILeadItem,
    field: IField,
): IIncomingDataItem[] => {
    return [
        {
            field: "Nombre de cliente",
            value: lead.display_name ?? "",
            description: "Nombre del cliente",
        },
        {
            field: "Dirección del cliente",
            value: lead.address || "Sin dirección",
            description: "Dirección del cliente",
        },
        {
            field: "Fecha de inspección previa",
            value:
                moment(field?.input_data.created_at).format("YYYY-MM-DD") ?? "",
            description: "Número que identifica el documento",
        },
    ];
};
