import { Box, Container, NavSidebar } from "@enerbit/base";
import type { FC, ReactNode } from "react";

interface Props {
    children: ReactNode;
}
const MainLayout: FC<Props> = ({ children }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                height: "100vh",
                width: "100vw",
                overflow: "hidden",
            }}
        >
            <NavSidebar />
            <Box
                sx={{
                    position: "relative",
                    flexGrow: 1,
                    paddingTop: "70px",
                    overflow: "auto",
                }}
            >
                <Container sx={{ py: 4 }} maxWidth={"xl"}>
                    {children}
                </Container>
            </Box>
        </div>
    );
};

export default MainLayout;
