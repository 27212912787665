import type {
    IElectricalConductors,
    IMeasurementSystem,
    IMeasurementSystemItem,
} from "../../interfaces";

export const generateElectricalConductorsMS = (
    measurement_system?: IMeasurementSystem,
    electrical_conductors?: IElectricalConductors | null,
): IMeasurementSystemItem[] => {
    if (!measurement_system || !electrical_conductors) return [];

    const items = [
        {
            field: "Longitud (m) conductor señal de corriente",
            currentValue:
                measurement_system?.electrical_conductors_current_signal_length
                    ?.current_value_situation ?? "",
            realValue:
                measurement_system?.electrical_conductors_current_signal_length
                    ?.ideal_value_situation ?? "",
            verification:
                !!measurement_system
                    ?.electrical_conductors_current_signal_length?.validation,
        },
        {
            field: "Calibre conductor señal de corriente",
            currentValue:
                measurement_system?.electrical_conductors_current_signal_gauge
                    .current_value_situation ?? "",
            realValue:
                measurement_system?.electrical_conductors_current_signal_gauge
                    .ideal_value_situation ?? "",
            verification:
                !!measurement_system?.electrical_conductors_current_signal_gauge
                    .validation,
        },
        {
            field: "Carga conductor señal de corriente (VA)",
            currentValue:
                measurement_system?.electrical_conductors_current_signal
                    ?.current_value_situation ?? "",
            realValue:
                measurement_system?.electrical_conductors_current_signal
                    ?.ideal_value_situation ?? "",
            verification:
                !!measurement_system?.electrical_conductors_current_signal
                    ?.validation,
        },
        {
            field: "Longitud (m) conductor señal de tensión",
            currentValue:
                measurement_system?.electrical_conductors_voltage_signal_length
                    ?.current_value_situation ?? "",
            realValue:
                measurement_system?.electrical_conductors_voltage_signal_length
                    ?.ideal_value_situation ?? "",
            verification:
                !!measurement_system
                    ?.electrical_conductors_voltage_signal_length?.validation,
        },
        {
            field: "Calibre conductor señal de tensión",
            currentValue:
                measurement_system?.electrical_conductors_voltage_signal_gauge
                    ?.current_value_situation ?? "",
            realValue:
                measurement_system?.electrical_conductors_voltage_signal_gauge
                    ?.ideal_value_situation ?? "",
            verification:
                !!measurement_system?.electrical_conductors_voltage_signal_gauge
                    ?.validation,
        },
        {
            field: "Carga conductor señal de tensión (VA)",
            currentValue:
                measurement_system?.electrical_conductors_voltage_signal
                    ?.current_value_situation ?? "",
            realValue:
                measurement_system?.electrical_conductors_voltage_signal
                    ?.ideal_value_situation ?? "",
            verification:
                !!measurement_system?.electrical_conductors_voltage_signal
                    ?.validation,
        },
    ];

    const filteredItems = items.filter(
        (item) => item.currentValue !== "" || item.realValue !== "",
    );

    return filteredItems;
};
