import { Grid, useSnackbar } from "@enerbit/base";
import { useEffect } from "react";
import { EmissionTabs } from ".";
import { EEntity } from "../../interfaces";
import { useEmissionStore, useTableStore } from "../../store";
import { SearchAddress } from "../address-table";
import { LastDiagnostic } from "../last-diagnostic";
import { EmissionSkeleton } from "../skeleton";
import OrderInstallation from "./OrderInstallation";

export const Emission = () => {
    const { addedEntities, listLeads } = useTableStore();
    const {
        loadingEmission,
        incomingData,
        previous_diagnostic,
        errorMessage,
        getOutputData,
        clearError,
    } = useEmissionStore();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (addedEntities.length > 0) {
            const lead = listLeads.find(({ id }) => addedEntities[0].id === id);
            if (lead) {
                getOutputData(lead);
            }
        }
    }, [addedEntities, getOutputData, listLeads]);

    useEffect(() => {
        if (errorMessage && !loadingEmission) {
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }, [errorMessage, loadingEmission, enqueueSnackbar]);

    useEffect(() => {
        clearError();
    }, [clearError]);

    return (
        <Grid container spacing={3}>
            <SearchAddress existsEntity={EEntity.Lead} />
            {addedEntities.length > 0 && (
                <>
                    {previous_diagnostic && <LastDiagnostic />}
                    {!loadingEmission && incomingData && <OrderInstallation />}
                    {incomingData && <EmissionTabs />}
                </>
            )}
            {loadingEmission && <EmissionSkeleton />}
        </Grid>
    );
};
