import { api } from "@enerbit/base";
import { AxiosError } from "axios";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { OrderTypes } from "../enums/solar/ordersType";
import type { ILeadItem, IRespFrontier, ITableState } from "../interfaces";

const initialState = {
    search: "",
    name: "",
    entity: null,
    listLeads: [],
    listCustomers: [],
    loading: false,
    page: 0,
    size: 5,
    rowCount: 1,
    selectedLeads: [],
    selectedCustomers: [],
    open: false,
    openAdd: false,
    addedEntities: [],
    errorMessage: "",
    diagnosticType: OrderTypes.conventional,
    loadingFrontier: false,
};

export const useTableStore = create<ITableState>()(
    devtools(
        (set) => ({
            ...initialState,
            setSearch: (search) => set({ search: search }),
            setDiagnosticType: (diagnosticType) =>
                set({ diagnosticType: diagnosticType }),
            setEntity: (entity) => set({ entity: entity }),
            resetList: () => set({ listLeads: [], listCustomers: [] }),
            setName: (name) => set({ name }),
            setPage: (page) => set({ page }),
            setPageSize: (size) => set({ size }),
            handleLead: (lead) => set(() => ({ selectedLeads: [lead] })),
            handleLeads: (lead) =>
                set((state) => {
                    const exists = !!state.selectedLeads.find(
                        ({ id }) => id === lead.id,
                    );
                    return {
                        selectedLeads: exists
                            ? state.selectedLeads.filter(
                                  ({ id }) => id !== lead.id,
                              )
                            : [...state.selectedLeads, lead],
                    };
                }),
            handleCustomer: (customer) =>
                set({ selectedCustomers: [customer] }),
            handleCustomers: (customer) =>
                set((state) => {
                    const exists = !!state.selectedCustomers.find(
                        ({ measurement_point_id }) =>
                            measurement_point_id ===
                            customer.measurement_point_id,
                    );
                    return {
                        selectedCustomers: exists
                            ? state.selectedCustomers.filter(
                                  ({ measurement_point_id }) =>
                                      measurement_point_id !==
                                      customer.measurement_point_id,
                              )
                            : [...state.selectedCustomers, customer],
                    };
                }),
            openModal: () =>
                set({
                    open: true,
                    addedEntities: [],
                    listLeads: [],
                    listCustomers: [],
                }),
            closeModal: () =>
                set((state) => ({
                    ...initialState,
                    addedEntities: state.addedEntities,
                    listLeads: state.listLeads,
                    listCustomers: state.listCustomers,
                    search: state.search,
                    diagnosticType: state.diagnosticType,
                })),
            openAddModal: () => set({ openAdd: true }),
            closeAddModal: () => set({ openAdd: false }),
            getLeads: async (email, page = 1, size = 5, name = "") => {
                set({ loading: true });
                try {
                    const parsedEmail = encodeURIComponent(email);
                    const response = await api.get(
                        `/assignees/leads?email=${parsedEmail}&page=${page}&size=${size}${name ? `&contact_name=${name}` : ""}`,
                    );
                    set((state) => ({
                        loading: false,
                        listLeads: response.data.items,
                        rowCount: response.data.total,
                        open: !state.openAdd,
                    }));
                } catch (error) {
                    set((state) => {
                        let errorMessage = "Ha ocurrido un error inesperado.";
                        if (error instanceof AxiosError) {
                            errorMessage =
                                error.response?.status === 404
                                    ? ""
                                    : "Ha ocurrido un error inesperado.";
                        } else if (error instanceof Error) {
                            errorMessage = error.message;
                        }

                        return {
                            page: 1,
                            rowCount: 1,
                            loading: false,
                            errorMessage: !state.name ? errorMessage : "",
                            listLeads: state.name ? [] : state.listLeads,
                        };
                    });
                }
            },
            getCustomers: async (email, page = 0, size = 5, name = "") => {
                set({ loading: true });
                try {
                    const parsedEmail = encodeURIComponent(email);
                    const response = await api.get(
                        `/assignees/customers?email=${parsedEmail}&page=${page}&size=${size}${name ? `&names=${name}` : ""}`,
                    );
                    set((state) => ({
                        loading: false,
                        listCustomers: response.data.items,
                        rowCount: Math.ceil(response.data.total / state.size),
                        open: !state.openAdd,
                    }));
                } catch (error) {
                    set((state) => {
                        let errorMessage = "Ha ocurrido un error inesperado.";
                        if (error instanceof AxiosError) {
                            errorMessage =
                                error.response?.status === 404
                                    ? ""
                                    : "Ha ocurrido un error inesperado.";
                        } else if (error instanceof Error) {
                            errorMessage = error.message;
                        }

                        return {
                            page: 0,
                            rowCount: 1,
                            loading: false,
                            errorMessage: !state.name ? errorMessage : "",
                            listCustomers: state.name
                                ? []
                                : state.listCustomers,
                        };
                    });
                }
            },
            getFrontier: async (frt: string) => {
                set({
                    loadingFrontier: true,
                    errorMessage: "",
                    addedEntities: [],
                });
                try {
                    const respFrontier = await api.get<IRespFrontier>(
                        `assignees/frontiers?frontier_xm_code=${frt}&page=0&size=50`,
                    );

                    const items = respFrontier.data.items;
                    if (items.length === 0) {
                        throw new Error("No existe esta frontera.");
                    }

                    const { lead_id } = items[0];

                    const handleLD = async () => {
                        const respLead = await api.get<ILeadItem>(
                            `assignees/leads/${lead_id}`,
                        );

                        set(() => ({
                            listLeads: [respLead.data],
                            addedEntities: [
                                {
                                    id: respLead.data.id,
                                    full_name: respLead.data.display_name,
                                    address: respLead.data.address,
                                    email: respLead.data.email_from,
                                    city: respLead.data.city,
                                    department: respLead.data.department,
                                },
                            ],
                        }));
                    };

                    if (lead_id) await handleLD();
                    else throw new Error("Lead no encontrado.");

                    set({ loadingFrontier: false });
                } catch (_error) {
                    set({
                        entity: null,
                        loadingFrontier: false,
                        errorMessage: "Ha ocurrido un error.",
                    });
                }
            },
            handleAddEntity: (entity) => set({ addedEntities: [entity] }),
            handleAddEntities: (entities) => set({ addedEntities: entities }),
            resetPartialTable: () =>
                set((state) => ({
                    ...initialState,
                    addedEntities: state.addedEntities,
                    listLeads: state.listLeads,
                    listCustomers: state.listCustomers,
                    search: state.search,
                    diagnosticType: state.diagnosticType,
                })),
            resetTable: () =>
                set(() => ({
                    ...initialState,
                })),
        }),
        {
            anonymousActionType: "table",
        },
    ),
);
