import {
    AddCircleOutlineIcon,
    Box,
    Button,
    DeleteIcon,
    EditIcon,
    Grid,
    type GridColDef,
    type GridRenderCellParams,
    IconButton,
    Tooltip,
    esES,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { StyledDataGrid } from "../../../common";
import type { IIncomingDataItemInverter, IInverter } from "../../../interfaces";
import { useEmissionStoreSolar } from "../../../store/emission-solar";
import { AddInverterSolar } from "./AddInverterSolar";

export const InverterTableSolar = () => {
    const [open, setOpen] = useState(false);
    const handleModal = () => setOpen(!open);
    const {
        deleteInverter,
        formEditableData,
        invertersSolar,
        updateInvertersAll,
        updateInvertersOld,
        getBrands,
        setEditObjectInverter,
    } = useEmissionStoreSolar();

    useEffect(() => {
        getBrands();
    }, []);

    useEffect(() => {
        if (formEditableData?.sfv_information.installation_type === "Medidor") {
            updateInvertersAll();
        } else {
            updateInvertersOld();
        }
    }, [formEditableData?.sfv_information.installation_type]);

    const columnsInverters: GridColDef[] = [
        {
            field: "inverter_brand",
            headerClassName: "custom-header",
            headerName: "Marca inversor",
            flex: 1,
            align: "left",
            headerAlign: "left",
            sortable: false,
            disableColumnMenu: true,
            editable: true,
            renderCell: (
                params: GridRenderCellParams<string, IIncomingDataItemInverter>,
            ) => {
                return (
                    <Box>
                        {params.row.inverter_brand === "Otro"
                            ? params.row.inverter_brand_new
                            : params.row.inverter_brand}
                    </Box>
                );
            },
        },
        {
            field: "inverter_model",
            headerClassName: "custom-header",
            headerName: "Modelo inversor",
            editable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (
                params: GridRenderCellParams<string, IIncomingDataItemInverter>,
            ) => {
                return (
                    <Box>
                        {params.row.inverter_model === "Otro"
                            ? params.row.inverter_model_new
                            : params.row.inverter_model}
                    </Box>
                );
            },
        },
        {
            field: "inverter_serial",
            headerClassName: "custom-header",
            headerName: "Serial inversor",
            editable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "id",
            headerClassName: "custom-header",
            headerName: "",
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableColumnMenu: true,
            flex: 1,
            renderCell: (
                params: GridRenderCellParams<string, IIncomingDataItemInverter>,
            ) => {
                return (
                    <>
                        <Tooltip title="Editar cantidad">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setEditObjectInverter(
                                        params.row as IInverter,
                                    );
                                    handleModal();
                                }}
                                color="primary"
                                disabled={
                                    formEditableData?.sfv_information
                                        .installation_type === "Medidor"
                                }
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                            <IconButton
                                size="small"
                                color="error"
                                disabled={
                                    formEditableData?.sfv_information
                                        .installation_type === "Medidor"
                                }
                                onClick={() =>
                                    deleteInverter(
                                        params.row.inverter_serial ?? "",
                                    )
                                }
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <Box>
            <Grid item xs={12}>
                <Box
                    sx={{
                        width: "100%",
                        "& .custom-header": {
                            backgroundColor: "#EFE9FB",
                            "&:first-of-type": {
                                borderTopLeftRadius: "12px",
                                borderBottomLeftRadius: "12px",
                            },
                            "&:last-child": {
                                borderTopRightRadius: "12px",
                                borderBottomRightRadius: "12px",
                            },
                        },
                    }}
                >
                    <StyledDataGrid
                        sx={{
                            "& .MuiDataGrid-virtualScrollerContent": {},
                        }}
                        columns={columnsInverters}
                        pageSize={100}
                        autoHeight
                        hideFooter
                        paginationMode="client"
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        editMode="row"
                        rows={
                            invertersSolar?.map((item, index) => ({
                                ...item,
                                id: index,
                            })) ?? []
                        }
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box
                    sx={{ display: "flex", justifyContent: "end", mt: "1rem" }}
                >
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<AddCircleOutlineIcon />}
                        size="small"
                        onClick={handleModal}
                        disabled={
                            formEditableData?.sfv_information
                                .installation_type === "Medidor"
                        }
                    >
                        Agregar nuevo campo
                    </Button>
                    {open && (
                        <AddInverterSolar
                            open={open}
                            handleClose={() => {
                                setEditObjectInverter();
                                handleModal();
                            }}
                        />
                    )}
                </Box>
            </Grid>
        </Box>
    );
};
