import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

export const StyledAccordion = styled(MuiAccordion)(({}) => ({
    borderRadius: "16px !important",
}));

export const StyledAccordionSummary = styled(MuiAccordionSummary)(
    ({ theme }) => ({
        minHeight: 56,
        borderRadius: "16px !important",
        "&.Mui-expanded": {
            minHeight: 56,
            borderRadius: "16px 16px 0 0 !important",
        },
        flexDirection: "row-reverse",
        "& .MuiAccordionSummary-content": {
            marginLeft: theme.spacing(2),
            "&.Mui-expanded": {
                margin: "20px !important",
            },
        },
    }),
);

export const StyledAccordionDetails = styled(MuiAccordionDetails)(
    ({ theme }) => ({
        padding: theme.spacing(2),
        // Aquí puedes añadir más estilos personalizados
    }),
);
