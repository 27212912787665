import type { IEmitDiagnosticSolar } from "../../../interfaces/solar/IEmitDiagnosticSolar";
import type {
    IFieldSolar,
    IInputDataFormDataSolar,
} from "../../../interfaces/solar/IRespOutputDataSolar";

/**
 * Obtains the values ​​of the output-solar endpoint and organizes them in such
 * a way that if it has a previous diagnosis, those from the previous diagnosis
 * are painted, if not, those from the inspection
 *
 * @param {IFieldSolar} field - Its initial inspection.
 * @param {IEmitDiagnosticSolar} previous_diagnostic - Its previus diagnostic.
 *
 */
export const obtainsInspectionOrPreviousDiag = (
    field: IFieldSolar,
    previous_diagnostic?: IEmitDiagnosticSolar,
): IInputDataFormDataSolar => {
    const sfvInformation = field?.input_data.form_data.sfv_information || {};
    const accessInstallation =
        field?.input_data.form_data.access_installation || {};
    const featuresSolarMeter =
        field?.input_data.form_data.features_solar_meter || {};
    const observations = field?.input_data.form_data.observations || {};

    const newSfvInformation = previous_diagnostic
        ? {
              ...sfvInformation,
              installation_type:
                  previous_diagnostic.form_data.solar_diagnostic
                      .installation_type,
              inverter_type:
                  previous_diagnostic.form_data.solar_diagnostic.inverter_type,
              epecista: previous_diagnostic.form_data.solar_diagnostic.epecista,
              epecista_new:
                  previous_diagnostic.form_data.solar_diagnostic.epecista_new,
              kwp_capacity_sfv:
                  previous_diagnostic.form_data.solar_diagnostic
                      .kwp_capacity_sfv,
              dongle_exists:
                  previous_diagnostic.form_data.solar_diagnostic.dongle_exists,
              dtu_exists:
                  previous_diagnostic.form_data.solar_diagnostic.dtu_exists,
              inverters:
                  previous_diagnostic.form_data.solar_diagnostic.inverters.map(
                      (value) => ({
                          inverter: value,
                      }),
                  ),
          }
        : sfvInformation;

    const newAccessInstallation = previous_diagnostic
        ? {
              ...accessInstallation,
              smartbit_meter_integration:
                  previous_diagnostic.form_data.solar_diagnostic
                      .smartbit_meter_integration,
              de_energization_energy_network:
                  previous_diagnostic.form_data.solar_diagnostic
                      .de_energization_energy_network,
              de_energization_energy_inverter:
                  previous_diagnostic.form_data.solar_diagnostic
                      .de_energization_energy_inverter,
          }
        : accessInstallation;

    const newFeaturesSolarMeter = previous_diagnostic
        ? {
              meter_location:
                  previous_diagnostic?.form_data.solar_diagnostic
                      .meter_location ?? "",
              meter_high:
                  Number(
                      previous_diagnostic?.form_data.solar_diagnostic
                          .meter_high,
                  ) ?? 0,
              measurement_type:
                  previous_diagnostic?.form_data.solar_diagnostic
                      .measurement_type ?? "",
              meter_size:
                  previous_diagnostic?.form_data.solar_diagnostic.meter_size ??
                  "",
              number_of_phases:
                  previous_diagnostic?.form_data.solar_diagnostic
                      .number_of_phases ?? "",
              number_meter_element:
                  previous_diagnostic?.form_data.solar_diagnostic
                      .number_meter_element ?? "",
              number_connection_elements:
                  previous_diagnostic?.form_data.solar_diagnostic
                      .number_connection_elements ?? "",
          }
        : {
              ...featuresSolarMeter,
              meter_high: Number(featuresSolarMeter.meter_high),
          };

    const newObservations = {
        ...observations,
        special_tools: previous_diagnostic
            ? previous_diagnostic.form_data.solar_diagnostic.special_tool
            : observations.special_tools,
    };

    return {
        ...field?.input_data.form_data,
        sfv_information: newSfvInformation,
        access_installation: newAccessInstallation,
        features_solar_meter: newFeaturesSolarMeter,
        observations: newObservations,
    };
};
