import type { ComponentType } from "react";
import type { IControlType, IDynamicForm } from "../../interfaces";
import { AccordionForm } from "./items/AccordionForm";
import { ArrayForm } from "./items/ArrayForm";
import { SelectForm } from "./items/SelectForm";
import SwitchForm from "./items/SwitchForm";
import { TextForm } from "./items/TextForm";
import { TitleForm } from "./items/TitleForm";

export const ComponentMap: Record<IControlType, ComponentType<IDynamicForm>> = {
    text: TextForm,
    email: TextForm,
    number: TextForm,
    "text-area": TextForm,
    password: TextForm,
    select: SelectForm,
    array: ArrayForm,
    title: TitleForm,
    accordion: AccordionForm,
    "simple-switch": SwitchForm,
};
