import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    WarningIcon,
} from "@enerbit/base";
import type { FC } from "react";
import { StyledDialog, StyledDialogTitle } from "../../common";

interface ConfirmDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const EmissionDialog: FC<ConfirmDialogProps> = ({
    open,
    onClose,
    onConfirm,
}) => {
    return (
        <StyledDialog open={open}>
            <StyledDialogTitle onClose={onClose} id={"id-confirm-edit"} />
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <WarningIcon
                        color="secondary"
                        sx={{ textAlign: "center" }}
                        fontSize={"large"}
                    />
                </Box>
                <DialogContentText
                    sx={{ color: "#53358E", textAlign: "center" }}
                >
                    ¿Esta seguro de confirmar esta acción?
                </DialogContentText>
                <DialogContentText
                    sx={{
                        color: "#53358E",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textAlign: "center",
                    }}
                >
                    Se enviará una notificación al cliente y no se podrá
                    deshacer.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    fullWidth
                    onClick={onConfirm}
                    color="secondary"
                    variant="contained"
                >
                    Aceptar
                </Button>
                <Button
                    fullWidth
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                >
                    Cancelar
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};
