import {
    Button,
    FormControl,
    Grid,
    MenuItem,
    SearchIcon,
    Select,
    type SelectChangeEvent,
    TextField,
    useSnackbar,
} from "@enerbit/base";
import { type FC, useEffect } from "react";
import { AddressModal } from ".";
import { OrderTypes } from "../../enums/solar/ordersType";
import type { EEntity } from "../../interfaces";
import { useTableStore } from "../../store";

interface Props {
    existsEntity?: EEntity;
}

export const SearchAddress: FC<Props> = ({ existsEntity }) => {
    const { enqueueSnackbar } = useSnackbar();

    const {
        loading,
        open,
        search,
        diagnosticType,
        errorMessage,
        openModal,
        setSearch,
        setDiagnosticType,
        getFrontier,
    } = useTableStore();

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const value = e.target.value;
        setSearch(value);
    };

    const handleFilterChange = (e: SelectChangeEvent<string | null>): void => {
        const value = e.target.value as OrderTypes;
        value && setDiagnosticType(value);
    };

    const searchLead = async () => {
        const cleanedFilter = search.replace(/\s+/g, "");
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (cleanedFilter) {
            if (emailRegex.test(cleanedFilter)) {
                openModal();
            } else if (cleanedFilter.toLowerCase().includes("frt")) {
                getFrontier(cleanedFilter);
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            searchLead();
        }
    };

    useEffect(() => {
        if (errorMessage) {
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }, [errorMessage, enqueueSnackbar]);

    return (
        <>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Grid container>
                        <Grid item xs={2}>
                            <Select
                                sx={{
                                    "& fieldset": {
                                        borderRadius: "14px 0px 0px 14px",
                                    },
                                }}
                                fullWidth
                                value={diagnosticType}
                                onChange={handleFilterChange}
                            >
                                <MenuItem value={OrderTypes.conventional}>
                                    Convencional
                                </MenuItem>
                                <MenuItem value={OrderTypes.solar}>
                                    Solar
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                onKeyDown={handleKeyDown}
                                onChange={handleInputChange}
                                disabled={loading}
                                value={search}
                                type="email"
                                id="email"
                                variant="outlined"
                                placeholder={
                                    OrderTypes.conventional
                                        ? "Buscar por correo electrónico o Lead ID"
                                        : "Buscar por correo electrónico"
                                }
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderRadius: "0px 14px 14px 0px",
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item display="grid" xs={2}>
                            <Button
                                id="search"
                                color="secondary"
                                variant="contained"
                                disabled={!search}
                                onClick={searchLead}
                                sx={{
                                    ml: "10px",
                                }}
                                startIcon={<SearchIcon fontSize="small" />}
                            >
                                Buscar
                            </Button>
                        </Grid>
                    </Grid>
                </FormControl>
            </Grid>
            {open && <AddressModal existsEntity={existsEntity} />}
        </>
    );
};
