import {
    Grid,
    LoadingButton,
    api,
    decodedJwt,
    useSnackbar,
} from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { formDiagnosticSolar } from "../../../form-builders/solar/form-diagnostic-solar";
import { calculateTotal, formatPrice } from "../../../helpers";
import type { IEmitDiagnosticSolar } from "../../../interfaces/solar/IEmitDiagnosticSolar";
import { PostEmitDiagnosticSchemaSolar } from "../../../schemas/solar/PostEmitDiagnosticSolar";
import { useTableStore } from "../../../store";
import { useEmissionStoreSolar } from "../../../store/emission-solar";
import { FormItem } from "../../dynamic-form";
import { EmissionDialog } from "../EmissionDialog";

interface Props {
    handleClose: () => void;
}

export const PostEmitDiagnosticFormSolar: FC<Props> = ({ handleClose }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { addedEntities } = useTableStore();

    const {
        formDataSolar,
        formEditableData,
        invertersSolar,
        createPreviousDiagnostic,
        materialsSolar,
        installation,
        initialInspectionId,
    } = useEmissionStoreSolar();

    const totalMeter = calculateTotal(materialsSolar.materials_meter);
    const totalSmartbit = calculateTotal(materialsSolar.materials_smartbit);
    const total = totalMeter + totalSmartbit;
    const formattedTotal = formatPrice(total);

    const getUserInfo = async () => {
        const res = await decodedJwt();
        return {
            name: res?.full_name ?? "",
            email: res?.name ?? "",
            operator_id: res?.sub ?? "",
        };
    };

    const methods = useForm<IEmitDiagnosticSolar>({
        resolver: zodResolver(PostEmitDiagnosticSchemaSolar),
        defaultValues: {
            lead_id: addedEntities[0].id,
            file_path: "",
            form_data: {
                diagnostic: {
                    of_type: "field",
                    initial_inspection_id: initialInspectionId,
                    status: installation
                        ? total <= installation.totalInstallation
                            ? "ok"
                            : "pending"
                        : "",
                    reasons_update: "",
                },
                materials_and_labor: {
                    materials_smartbit:
                        formDataSolar?.access_installation.materials_smartbit ??
                        [],
                    materials_meter:
                        formDataSolar?.access_installation.materials_meter ??
                        [],
                    total_price: formattedTotal,
                },
                emited_by: null,
            },
        },
        mode: "onChange",
    });

    const { watch, getValues, trigger } = methods;

    const handleOpenDialog = async () => {
        if (await trigger()) setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async () => {
        if (await trigger()) {
            const values = getValues();
            await onSubmit(values);
            handleCloseDialog();
        }
    };

    const onSubmit = async (data: IEmitDiagnosticSolar) => {
        try {
            if (formEditableData) {
                setIsLoading(true);
                const userInfo = await getUserInfo();
                const diagnostic = formEditableData && {
                    ...data,
                    form_data: {
                        ...data.form_data,
                        emited_by: userInfo,
                        solar_diagnostic: {
                            ...formEditableData?.sfv_information,
                            ...formEditableData?.access_installation,
                            ...formEditableData?.features_solar_meter,
                            inverters: invertersSolar ?? [],
                            special_tool: String(
                                formDataSolar?.observations.special_tools ?? "",
                            ),
                        },
                        materials_and_labor: {
                            materials_smartbit:
                                formDataSolar?.access_installation
                                    .materials_smartbit ?? [],
                            materials_meter:
                                formDataSolar?.access_installation
                                    .materials_meter ?? [],
                            total_price: formattedTotal,
                        },
                    },
                };

                const { data: id } = await api.post<string>(
                    "/frontier-initial-diagnostic/emit-solar-diagnostic/",
                    diagnostic,
                );
                createPreviousDiagnostic({ ...diagnostic, id });
                enqueueSnackbar("Diagnóstico emitido con éxito.", {
                    variant: "success",
                });
                handleClose();
            }
        } catch (_error) {
            enqueueSnackbar(
                "No se pudo emitir el diagnóstico, inténtelo de nuevo.",
                {
                    variant: "error",
                },
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid container spacing={3}>
                    {formDiagnosticSolar.map((item, index) => {
                        const newItem = {
                            ...item,
                            name: `form_data.diagnostic.${item.name}`,
                        };
                        return <FormItem key={index.toString()} {...newItem} />;
                    })}
                    <Grid item xs={12}>
                        <LoadingButton
                            loading={isLoading}
                            onClick={
                                watch("form_data.diagnostic.status") === "ok"
                                    ? handleOpenDialog
                                    : handleConfirmDialog
                            }
                            color="secondary"
                            variant="contained"
                            fullWidth
                        >
                            Crear
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
            <EmissionDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDialog}
            />
        </FormProvider>
    );
};
