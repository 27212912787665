import {
    generateCommunications,
    generateCurrentTransformerID,
    generateElectricalConductorsID,
    generateGeneralData,
    generateHeader,
    generateMainMeterID,
    generateTerminalsID,
    generateVoltageTransformerID,
} from "..";
import type { IField, IIncomingDataItem, ILeadItem } from "../../interfaces";

export const generateIncomingData = (
    lead: ILeadItem,
    field: IField,
): Record<string, IIncomingDataItem[]> => {
    return {
        header: generateHeader(lead, field),
        generalData: generateGeneralData(lead, field),
        mainMeter: generateMainMeterID(field),
        cabinet: [
            {
                field: "Marca",
                value:
                    field.input_data.form_data.electrical_boxes?.[0]
                        .manufacturer?.value ?? "Sin información",
                description: "Marca del gabinete",
            },
            {
                field: "Fecha de fabricación",
                value:
                    field.input_data.form_data.electrical_boxes?.[0]
                        .manufacture_year?.value ?? "Sin información",
                description: "Fecha de fabricación del gabinete",
            },
        ],
        electricalConductors: generateElectricalConductorsID(field),
        communications: generateCommunications(
            field.input_data.form_data.comunications,
        ),
        currentTransformerPhaseR: generateCurrentTransformerID(
            field?.input_data.form_data.current_transformer?.r_phase,
        ),
        currentTransformerPhaseS: generateCurrentTransformerID(
            field?.input_data.form_data.current_transformer?.s_phase,
        ),
        currentTransformerPhaseT: generateCurrentTransformerID(
            field?.input_data.form_data.current_transformer?.t_phase,
        ),
        voltageTransformerPhaseR: generateVoltageTransformerID(
            field.input_data.form_data.voltage_transformer?.r_phase,
        ),
        voltageTransformerPhaseS: generateVoltageTransformerID(
            field.input_data.form_data.voltage_transformer?.s_phase,
        ),
        voltageTransformerPhaseT: generateVoltageTransformerID(
            field.input_data.form_data.voltage_transformer?.t_phase,
        ),
        terminals: generateTerminalsID(field),
    };
};
