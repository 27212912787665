import { Box } from "@enerbit/base";
import type { FC } from "react";

export const NoRowsOverlay: FC = () => {
    return (
        <Box sx={{ textAlign: "center", padding: "16px" }}>
            Sin información.
        </Box>
    );
};
