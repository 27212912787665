import { Grid } from "@enerbit/base";
import { useEmissionStoreSolar } from "../../store/emission-solar";
import { PhotographsSection } from "./PhotographsSection";
import { PhotographsSectionSolar } from "./PhotographsSectionSolar";

export const PhotographsSolar = () => {
    const { photographs } = useEmissionStoreSolar();

    if (!photographs) return null;

    const {
        sfv_information,
        access_installation,
        data_telemetry,
        one_line_diagram,
        photographic_annexes,
    } = photographs;

    return (
        <Grid container spacing={3}>
            <PhotographsSectionSolar
                name={"Información general"}
                data={sfv_information}
            />
            <PhotographsSectionSolar
                name={"Acceso de instalación"}
                data={access_installation}
            />
            <PhotographsSectionSolar
                name={"Salida de datos"}
                data={data_telemetry}
            />
            <PhotographsSection
                name={"Diagrama unifilar"}
                data={one_line_diagram}
            />
            <PhotographsSection
                name={"Otros anexos fotográficos"}
                data={photographic_annexes}
            />
        </Grid>
    );
};
