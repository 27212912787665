import {
    Box,
    Button,
    Grid,
    GridColDef,
    GridRenderCellParams,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    esES,
} from "@enerbit/base";
import { useEffect } from "react";
import useSWR from "swr";
import { StyledDataGrid } from "../../common";
import { IDiagnosticFormData } from "../../interfaces";
import { IDocumentElement, IGetDiagnostics } from "../../interfaces/diagnostic";
import { useTableStore, useViabilityDiagnosticStore } from "../../store";
import { useDiagnosticsStore } from "../../store/diagnostics.store";
import PaginationDiagnostic from "./PaginationDiagnostic";

const getStatusInSpanish = (status: string) => {
    switch (status) {
        case "ok":
            return "Viable";
        case "pending":
            return "Pendiente de adecuaciones";
        case "quoted":
            return "Cotizado";
        case "pending_quotation":
            return "Pendiente de cotización";
        case "no":
            return "No viable";
        default:
            return "Desconocido";
    }
};

const Diagnostics = () => {
    const {
        diagnostics,
        page,
        size,
        selectedStatus,
        setPage,
        setSize,
        setDiagnostics,
        setSelectedStatus,
    } = useDiagnosticsStore();
    const { setValueTab } = useViabilityDiagnosticStore();
    const { setSearch, setName, openModal } = useTableStore();

    const { data, isLoading } = useSWR<IGetDiagnostics>(
        `/frontier-initial-diagnostic/diagnostics?page=${page}&size=${size}${
            selectedStatus ? `&status=${selectedStatus}` : ""
        }`,
    );

    useEffect(() => {
        if (data && !isLoading) {
            setDiagnostics(data.documents);
        }
    }, [data, setDiagnostics, isLoading]);

    const handleStatusChange = (event: SelectChangeEvent<string>) => {
        setSelectedStatus(event.target.value as string);
        setPage(0);
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerClassName: "custom-header",
            headerName: "Nombre",
            flex: 1,
            align: "left",
            headerAlign: "left",
            sortable: false,
            disableColumnMenu: true,
            minWidth: 75,
        },
        {
            field: "address",
            headerClassName: "custom-header",
            headerName: "Dirección",
            flex: 1,
            align: "left",
            headerAlign: "left",
            sortable: false,
            disableColumnMenu: true,
            minWidth: 75,
        },
        {
            field: "document",
            headerClassName: "custom-header",
            headerName: "Estado",
            flex: 1,
            align: "left",
            headerAlign: "left",
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: (
                params: GridRenderCellParams<
                    IDiagnosticFormData,
                    IDocumentElement
                >,
            ) => {
                const status = params.value?.diagnostic.status ?? "";
                return getStatusInSpanish(status);
            },
        },
        {
            field: "odoo_url",
            headerClassName: "custom-header",
            headerName: "Acciones",
            sortable: false,
            flex: 1,
            align: "left",
            headerAlign: "left",
            disableColumnMenu: true,
            minWidth: 200,
            renderCell: (
                params: GridRenderCellParams<string, IDocumentElement>,
            ) => {
                const handleOpenOdoo = () => {
                    window.open(params.value, "_blank");
                };

                const handleReview = () => {
                    setValueTab(0);
                    openModal();
                    setSearch(params.row.email);
                    setName(params.row.name);
                };

                return (
                    <Box sx={{ display: "flex" }}>
                        <Stack direction={"row"} spacing={2}>
                            <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                onClick={handleOpenOdoo}
                            >
                                Odoo
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={handleReview}
                            >
                                Revisar
                            </Button>
                        </Stack>
                    </Box>
                );
            },
        },
    ];

    const totalPageCount = data ? Math.ceil(data.total / size) : 0;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box mb={2} sx={{ display: "flex", justifyContent: "end" }}>
                    <Select
                        value={selectedStatus}
                        onChange={handleStatusChange}
                        displayEmpty
                        variant="outlined"
                    >
                        <MenuItem value="">Todos los estados</MenuItem>
                        <MenuItem value="ok">Viable</MenuItem>
                        <MenuItem value="pending">
                            Pendiente de adecuaciones
                        </MenuItem>
                        <MenuItem value="quoted">Cotizado</MenuItem>
                        <MenuItem value="pending_quotation">
                            Pendiente de cotización
                        </MenuItem>
                        <MenuItem value="no">No viable</MenuItem>
                    </Select>
                </Box>

                <Box
                    sx={{
                        width: "100%",
                        "& .custom-header": {
                            backgroundColor: "#EFE9FB",
                            "&:first-of-type": {
                                borderTopLeftRadius: "12px",
                                borderBottomLeftRadius: "12px",
                            },
                            "&:last-child": {
                                borderTopRightRadius: "12px",
                                borderBottomRightRadius: "12px",
                            },
                        },
                    }}
                >
                    <StyledDataGrid
                        sx={{
                            "& .MuiDataGrid-virtualScrollerContent": {
                                height:
                                    data?.documents.length ?? 0 > 0
                                        ? "auto!important"
                                        : "100px!important",
                            },
                            "& .MuiDataGrid-row": {
                                minHeight: "52px!important",
                                py: 1,
                            },
                        }}
                        getRowHeight={() => "auto"}
                        columns={columns}
                        rows={diagnostics.map((item, index) => ({
                            ...item,
                            id: index,
                        }))}
                        loading={isLoading}
                        page={page}
                        pageSize={size}
                        onPageChange={setPage}
                        onPageSizeChange={setSize}
                        paginationMode="server"
                        rowCount={data?.total}
                        autoHeight
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        components={{
                            Pagination: () => (
                                <PaginationDiagnostic
                                    page={page}
                                    pageSize={size}
                                    count={totalPageCount}
                                    isLoading={isLoading}
                                    onPageChange={(newPage) => setPage(newPage)}
                                />
                            ),
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default Diagnostics;
