import {
    Grid,
    LoadingButton,
    api,
    decodedJwt,
    useSnackbar,
} from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { formDiagnosticSolar } from "../../../form-builders/solar/form-diagnostic-solar";
import {
    calculateTotal,
    formatPrice,
    restoreTransformMaterials,
} from "../../../helpers";
import type { IDiagnosticFormData } from "../../../interfaces";
import { PatchEmitDiagnosticSchema } from "../../../schemas";
import { useEmissionStore } from "../../../store";
import { FormItem } from "../../dynamic-form";
import { EmissionDialog } from "../EmissionDialog";

interface Props {
    handleClose: () => void;
}

export const PatchEmitDiagnosticFormSolar: FC<Props> = ({ handleClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const { materials, previous_diagnostic, updatePreviousDiagnostic } =
        useEmissionStore();

    const total = calculateTotal(materials);

    const getUserInfo = async () => {
        const res = await decodedJwt();
        return {
            name: res?.full_name ?? "",
            email: res?.name ?? "",
            operator_id: res?.sub ?? "",
        };
    };

    const methods = useForm<IDiagnosticFormData>({
        resolver: zodResolver(PatchEmitDiagnosticSchema),
        defaultValues: {
            diagnostic: {
                ...previous_diagnostic?.form_data.diagnostic,
                reasons_update: "",
                of_type: "field",
            },
            materials_and_labor: {
                materials: restoreTransformMaterials(materials),
                total_price: formatPrice(total),
            },
            emited_by: null,
        },
        mode: "onChange",
    });

    const { getValues, watch, trigger } = methods;

    const handleOpenDialog = async () => {
        if (await trigger()) setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async () => {
        if (await trigger()) {
            const values = getValues();
            await onSubmit(values);
            handleCloseDialog();
        }
    };

    const onSubmit = async (data: IDiagnosticFormData) => {
        try {
            setIsLoading(true);
            const userInfo = await getUserInfo();
            const diagnostic: IDiagnosticFormData = {
                ...data,
                emited_by: userInfo,
            };
            await api.patch(
                `/frontier-initial-diagnostic/emit-diagnostic?_id=${previous_diagnostic?.id}`,
                diagnostic,
            );
            updatePreviousDiagnostic(diagnostic);
            enqueueSnackbar("Diagnóstico editado con éxito.", {
                variant: "success",
            });
            handleClose();
        } catch (_error) {
            enqueueSnackbar(
                "No se pudo editar el diagnóstico, inténtelo de nuevo.",
                {
                    variant: "error",
                },
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid container spacing={3}>
                    {formDiagnosticSolar.map((item, index) => {
                        const isDisabled =
                            item.name === "status" &&
                            previous_diagnostic?.form_data.diagnostic.status ===
                                "ok";
                        const newItem = {
                            ...item,
                            name: `diagnostic.${item.name}`,
                            ...(item.name === "status" && {
                                props: {
                                    disabled: isDisabled,
                                },
                            }),
                        };
                        return <FormItem {...newItem} key={index.toString()} />;
                    })}
                    <FormItem
                        type={"text-area"}
                        name="diagnostic.reasons_update"
                        label="Razones de la actualización"
                        sx={{ xs: 12 }}
                    />
                    <Grid item xs={12}>
                        <LoadingButton
                            loading={isLoading}
                            onClick={
                                watch("diagnostic.status") === "ok"
                                    ? handleOpenDialog
                                    : handleConfirmDialog
                            }
                            color="secondary"
                            variant="contained"
                            fullWidth
                        >
                            Editar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
            <EmissionDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDialog}
            />
        </FormProvider>
    );
};
