import { Pagination } from "@enerbit/base";
import { FC } from "react";

interface Props {
    page: number;
    pageSize: number;
    count: number;
    isLoading: boolean;
    onPageChange: (newPage: number) => void;
}

const PaginationDiagnostic: FC<Props> = ({
    count,
    page,
    isLoading,
    onPageChange,
}) => {
    return (
        <Pagination
            disabled={isLoading}
            shape="rounded"
            color="primary"
            count={count}
            page={page + 1}
            onChange={(_e, value) => onPageChange(value - 1)}
        />
    );
};

export default PaginationDiagnostic;
