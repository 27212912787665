import { Grid, useSnackbar } from "@enerbit/base";
import { useEffect } from "react";
import { EEntity } from "../../../interfaces";
import { useTableStore } from "../../../store";
import { useEmissionStoreSolar } from "../../../store/emission-solar";
import { SearchAddress } from "../../address-table";
import { LastDiagnosticSolar } from "../../last-diagnostic/solar/LastDiagnosticSolar";
import { EmissionSkeleton } from "../../skeleton";
import { EmissionTabs } from "../EmissionTabs";

export const EmissionSolar = () => {
    const { addedEntities, listLeads } = useTableStore();
    const {
        loadingEmission,
        incomingDataSolar: field,
        previous_diagnostic,
        getOutputDataSolar,
        errorMessage,
        clearError,
    } = useEmissionStoreSolar();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (addedEntities.length > 0) {
            const lead = listLeads.find(({ id }) => addedEntities[0].id === id);
            if (lead) {
                getOutputDataSolar(lead);
            }
        }
    }, [addedEntities, getOutputDataSolar, listLeads]);

    useEffect(() => {
        if (errorMessage && !loadingEmission) {
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }, [errorMessage, loadingEmission, enqueueSnackbar]);

    useEffect(() => {
        clearError();
    }, [clearError]);

    return (
        <Grid container spacing={3}>
            <SearchAddress existsEntity={EEntity.Lead} />
            {addedEntities.length > 0 && !loadingEmission && (
                <>
                    {previous_diagnostic && <LastDiagnosticSolar />}
                    {field && <EmissionTabs isSolar={true} />}
                </>
            )}
            {loadingEmission && <EmissionSkeleton />}
        </Grid>
    );
};
