import moment from "moment";
import type { IIncomingDataItem, ILeadItem } from "../../interfaces";

export const generateHeaderSolar = (
    lead: ILeadItem,
    createdAt: Date,
): IIncomingDataItem[] => {
    return [
        {
            field: "Nombre de cliente",
            value: lead.display_name ?? "",
            description: "Nombre del cliente",
        },
        {
            field: "Dirección del cliente",
            value: lead.address || "Sin dirección",
            description: "Dirección del cliente",
        },
        {
            field: "Fecha de inspección previa",
            value: moment(createdAt).format("YYYY-MM-DD") ?? "",
            description: "Número que identifica el documento",
        },
    ];
};
