export const formatPrice = (
    price: number,
    locale = "es-CO",
    currency = "COP",
): string => {
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
    }).format(price);
};

export const convertMoneyToString = (moneyString: string | number) => {
    let processedString: string;

    if (typeof moneyString === "number") {
        processedString = moneyString.toString();
    } else {
        processedString = moneyString;
    }

    const numericString = processedString.replace(/[$,]/g, "");

    return Number.isNaN(Number.parseFloat(numericString)) ? "0" : numericString;
};
