import { Grid } from "@enerbit/base";
import { useEmissionStore } from "../../store";
import { PhotographsSection } from "./PhotographsSection";

export const Photographs = () => {
    const { photographs } = useEmissionStore();

    if (!photographs) return null;

    const {
        general_data,
        main_meter,
        optional_meter,
        current_transformer,
        phases_current_transformer,
        voltage_transformer,
        phases_voltage_transformer,
        electrical_conductors,
        electrical_boxes,
        terminals,
        photographic_annexes,
    } = photographs;

    return (
        <Grid container spacing={3}>
            <PhotographsSection
                name={"Información general"}
                data={general_data}
            />
            <PhotographsSection name={"Medidor principal"} data={main_meter} />
            <PhotographsSection
                name={"Medidor opcional"}
                data={optional_meter}
            />
            {current_transformer && (
                <PhotographsSection
                    name={"Transformador de corriente ubicación"}
                    data={current_transformer}
                />
            )}
            {phases_current_transformer &&
                Object.entries(phases_current_transformer).map(
                    ([key, value], index) => {
                        return (
                            <PhotographsSection
                                name={`Transformador de corriente Fase ${key
                                    .charAt(0)
                                    .toUpperCase()}`}
                                data={value}
                                key={index.toString()}
                            />
                        );
                    },
                )}
            {voltage_transformer && (
                <PhotographsSection
                    name={"Transformador de voltaje ubicación"}
                    data={voltage_transformer}
                />
            )}
            {phases_voltage_transformer &&
                Object.entries(phases_voltage_transformer).map(
                    ([key, value], index) => {
                        return (
                            <PhotographsSection
                                name={`Transformador de voltage Fase ${key
                                    .charAt(0)
                                    .toUpperCase()}`}
                                data={value}
                                key={index.toString()}
                            />
                        );
                    },
                )}
            <PhotographsSection
                name={"Conductores eléctricos"}
                data={electrical_conductors}
            />
            <PhotographsSection
                name={"Cajas eléctricas"}
                data={electrical_boxes}
            />
            <PhotographsSection name={"Terminales"} data={terminals} />
            <PhotographsSection
                name={"Otros anexos fotográficos"}
                data={photographic_annexes}
            />
        </Grid>
    );
};
