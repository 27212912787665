import { capitalize } from "@enerbit/base";
import {
    type IField,
    type IIncomingDataItem,
    type ILeadItem,
} from "../../interfaces";
import {
    getAverageConsumeType,
    getConsumptionProjection,
    getInstallationCapacityType,
    getVoltageLevel,
} from "../calculateValues";

export const generateGeneralData = (
    lead: ILeadItem,
    field: IField,
): IIncomingDataItem[] => {
    const items = [
        {
            field: "Latitud",
            value:
                field?.input_data.form_data.general_data?.geolocalization
                    .latitude ?? "",
            description: "Latitud",
        },
        {
            field: "Longitud",
            value:
                field?.input_data.form_data.general_data?.geolocalization
                    .longitude ?? "",
            description: "Longitud",
        },
        {
            field: "Altitud",
            value:
                field?.input_data.form_data.general_data?.geolocalization
                    .altitude ?? "",
            description: "Altitud",
        },
        {
            field: "Consumo promedio (kWh)",
            value: lead.initial_average_active_energy_usage ?? "",
            description: "Registro histórico de consumos.",
        },
        {
            field: "Tensión de servicio (kV)",
            value:
                field?.input_data.form_data.general_data?.voltage_level ?? "",
            description: "Tensión nominal en kilovoltios de la conexión.",
        },
        {
            field: "Capacidad instalada (kVA)",
            value:
                field?.input_data.form_data.general_data
                    ?.power_capacity_contracted?.value ?? "",
            description: "Capacidad en el punto de conexión.",
        },
        {
            field: "Tensión primaria del transformador de potencia (kV)",
            value:
                field?.input_data.form_data.general_data
                    ?.power_transformer_primary_voltage ?? "",
            description: "Tensión primaria en kilovoltios de la placa.",
        },
        {
            field: "Tensión secundaria del transformador de potencia (V)",
            value:
                field?.input_data.form_data.general_data
                    ?.power_transformer_secundary_voltage ?? "",
            description: "Tensión en voltios sin afectarse por RAIZ(3).",
        },
        {
            field: "Número de fases del transformador de potencia",
            value:
                field?.input_data.form_data.general_data
                    ?.power_transformer_phase_number ?? "",
            description: "Número de fases.",
        },
        {
            field: "Uso del transformador de potencia",
            value: capitalize(
                field?.input_data.form_data.general_data
                    ?.power_transformer_usage ?? "",
            ),
            description: "Si es compartido o exclusivo.",
        },
        {
            field: "Nodo transformador de potencia",
            value:
                field?.input_data.form_data.general_data
                    ?.power_transformer_node ?? "",
            description: "Número de nodo o apoyo donde está instalado",
        },
        {
            field: "Ubicación transformador de potencia",
            value: capitalize(
                field?.input_data.form_data.general_data
                    ?.power_transformer_location ?? "",
            ),
            description: "Ubicación transformador de potenci",
        },
        {
            field: "Conexión",
            value: capitalize(
                field?.input_data.form_data.general_data?.connection ?? "",
            ),
            description: "Tipo de conexión en el punto de conexión.",
        },
        {
            field: "Factor liquidación externo",
            value:
                field?.input_data.form_data.general_data?.multiplier_factor ??
                0,
            description: "Múltiplo que afecta la lectura.",
        },
        {
            field: "Proyección de consumo",
            value: field?.input_data.form_data.general_data
                ?.power_capacity_contracted?.value
                ? getConsumptionProjection(
                      field?.input_data.form_data.general_data
                          ?.power_capacity_contracted.value,
                  )
                : "",
            description: "Proyección de consumo",
        },
        {
            field: "Nivel de tensión del punto de medida",
            value: field?.input_data.form_data.general_data
                ? getVoltageLevel(
                      field?.input_data.form_data.general_data?.voltage_level,
                  )
                : "",
            description: "Se define a partir de Tensión de servicio (kV)",
        },
        {
            field: "Tipo de punto de medición (Consumo promedio)",
            value: getAverageConsumeType(
                lead.initial_average_active_energy_usage,
            ),
            description: "Tipo de punto de medición (Consumo promedio)",
        },
        {
            field: "Tipo de punto de medición (Capacidad instalada)",
            value: field?.input_data.form_data.general_data
                ?.power_capacity_contracted
                ? getInstallationCapacityType(
                      field.input_data.form_data.general_data
                          .power_capacity_contracted?.value,
                  )
                : "",
            description: "Tipo de punto de medición (Capacidad instalada)",
        },
        {
            field: "Corriente primaria transformador de potencia (I)",
            value:
                field?.measurement_system?.power_transformer_primary_current
                    .current_value_situation ?? "",
            description: "Cálculo de corriente.",
        },
        {
            field: "Corriente secundaria transformador de potencia (I)",
            value:
                field?.measurement_system?.power_transformer_secundary_current
                    .current_value_situation ?? "",
            description: "Cálculo de corriente.",
        },
    ];

    const filteredItems = items.filter((item) => item.value !== "");

    return filteredItems;
};
