import {
    Alert,
    Button,
    CloseIcon,
    EditIcon,
    Grid,
    type GridColDef,
    type GridRenderCellParams,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    WarningIcon,
    useGridApiContext,
} from "@enerbit/base";
import { type ChangeEvent, useState } from "react";
import type { IIncomingDataItem } from "../../../interfaces";
import { useEmissionStoreSolar } from "../../../store/emission-solar";
import { EmissionTable } from "../EmissionTable";
import { EmitDiagnosticModal } from "../EmitDiagnosticModal";

export const ConceptSolar = () => {
    const {
        concept,
        previous_diagnostic,
        updateSpecialTools,
        formDataSolar,
        enableButton,
    } = useEmissionStoreSolar();

    const [open, setOpen] = useState(false);
    const [editingRow, setEditingRow] = useState<string | null>(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const columns: GridColDef[] = [
        {
            field: "field",
            headerClassName: "custom-header",
            headerName: "Nombre del campo",
            flex: 1,
            align: "left",
            headerAlign: "left",
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "value",
            headerClassName: "custom-header",
            headerName: "Observaciones",
            flex: 3,
            align: "left",
            headerAlign: "left",
            sortable: false,
            disableColumnMenu: true,
            editable: true,
            // renderCell: (params) => {
            //   const { id, value, field } = params;
            //   const apiRef = useGridApiContext();

            //   const handleValueChangeInput = (event: any) => {
            //     const newValue = event.target.value;
            //     apiRef.current.setEditCellValue({ id, field, value: newValue });
            //     updateSpecialTools(newValue, params.row.key);
            //   };

            //   if (params.row.key == "is_epecista_support") {
            //     return <Switch color="secondary" checked={true} onChange={handleValueChangeInput} />;
            //   }

            //   if (formDataSolar?.observations.epecista_requirement && params.row.key == "epecista_requirement") {
            //     return (
            //       <Box sx={{ p: 10 }}>
            //         <TextField
            //           fullWidth
            //           minRows={2}
            //           variant="outlined"
            //           value={formDataSolar?.observations.epecista_requirement ?? ""}
            //           InputLabelProps={{ shrink: false }}
            //           onChange={handleValueChangeInput}
            //         />
            //       </Box>
            //     );
            //   }
            // },
            renderEditCell: (params) => {
                const { id, field } = params;
                const apiRef = useGridApiContext();

                const handleValueChangeInput = (
                    event: ChangeEvent<{ value: string }>,
                ) => {
                    const newValue = event.target.value;
                    apiRef.current.setEditCellValue({
                        id,
                        field,
                        value: newValue,
                    });
                    updateSpecialTools(newValue);
                };

                if (params.row.key === "special_tools") {
                    return (
                        <TextField
                            fullWidth
                            minRows={2}
                            variant="outlined"
                            value={
                                formDataSolar?.observations.special_tools ?? ""
                            }
                            InputLabelProps={{ shrink: false }}
                            onChange={handleValueChangeInput}
                        />
                    );
                }
            },
        },
        {
            field: "description",
            headerClassName: "custom-header",
            headerName: "",
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableColumnMenu: true,
            renderCell: (
                params: GridRenderCellParams<string, IIncomingDataItem>,
            ) => {
                const apiRef = useGridApiContext();
                const { id } = params;
                if (
                    editingRow !== id &&
                    apiRef.current.getRowMode(id) === "edit"
                ) {
                    apiRef.current.setRowMode(editingRow as string, "view");
                }

                const handleEdit = () => {
                    setEditingRow(id as string);
                    apiRef.current.setRowMode(id, "edit");
                };

                const handleCancel = () => {
                    setEditingRow(null);
                    apiRef.current.setRowMode(id, "view");
                };
                return (
                    <>
                        {params.row.key === "special_tools" && (
                            <>
                                {apiRef.current.getRowMode(id) === "view" ? (
                                    <Tooltip title="Editar cantidad">
                                        <IconButton
                                            size="small"
                                            onClick={handleEdit}
                                            color="primary"
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Cancelar edición">
                                        <IconButton
                                            size="small"
                                            onClick={handleCancel}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <Grid container spacing={3}>
            <EmissionTable
                rows={concept.map((item, index) => ({ ...item, id: index }))}
                title="Comentarios"
                columns={columns}
            />

            {previous_diagnostic && (
                <Grid item xs={12}>
                    <Alert
                        icon={<WarningIcon fontSize="inherit" />}
                        severity="warning"
                    >
                        Este lead ya cuenta con un documento de diagnostico
                    </Alert>
                </Grid>
            )}

            {previous_diagnostic?.form_data.diagnostic.status !== "ok" && (
                <Grid item xs={12}>
                    <Stack direction={"row"} spacing={3}>
                        <Button
                            color="secondary"
                            variant="contained"
                            fullWidth
                            disabled={enableButton}
                            onClick={handleOpen}
                        >
                            {previous_diagnostic
                                ? "Actualizar diagnóstico"
                                : "Emitir diagnóstico"}
                        </Button>
                        <EmitDiagnosticModal
                            open={open}
                            handleClose={handleClose}
                        />
                    </Stack>
                </Grid>
            )}
        </Grid>
    );
};
