import {
    AddCircleOutlineIcon,
    Box,
    Button,
    Chip,
    Grid,
    InfoOutlinedIcon,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import { useState } from "react";
import {
    AddMaterialModal,
    MaterialLaborTable,
    MaterialQuotationTable,
    ViabilityError,
    ViabilityStatus,
} from ".";
import { formatPrice } from "../../helpers";
import { calculateTotal } from "../../helpers/material-labor";
import { useEmissionStore } from "../../store";
import { AddMaterialQuotationModal } from "./AddMaterialQuotationModal";

export const MaterialLabor = () => {
    const [openMaterials, setOpenMaterials] = useState(false);
    const [openQuotations, setOpenQuotations] = useState(false);

    const handleOpenMaterials = () => setOpenMaterials(true);
    const handleCloseMaterials = () => setOpenMaterials(false);

    const handleOpenQuotations = () => setOpenQuotations(true);
    const handleCloseQuotations = () => setOpenQuotations(false);

    const { materials, installation, material_quotation } = useEmissionStore();
    const total = calculateTotal(materials);
    const totalQuotation = calculateTotal(material_quotation);
    const formattedTotal = formatPrice(total + totalQuotation);

    return (
        <Grid container spacing={3}>
            {installation ? (
                <ViabilityStatus
                    status={
                        total + totalQuotation <= installation.totalInstallation
                    }
                />
            ) : (
                <ViabilityError />
            )}
            <Grid item>
                <Typography
                    sx={{
                        color: enerbitColors.primary.main,
                        fontWeight: "bold",
                    }}
                >
                    Materiales en inventario
                </Typography>
            </Grid>
            <MaterialLaborTable />
            <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<AddCircleOutlineIcon />}
                        size="small"
                        onClick={handleOpenMaterials}
                    >
                        Agregar nuevo campo
                    </Button>
                    {openMaterials && (
                        <AddMaterialModal
                            open={openMaterials}
                            handleClose={handleCloseMaterials}
                        />
                    )}
                </Box>
            </Grid>
            <Grid item>
                <Typography
                    sx={{
                        color: enerbitColors.primary.main,
                        fontWeight: "bold",
                    }}
                >
                    Materiales a cotizar
                </Typography>
            </Grid>
            <MaterialQuotationTable />
            <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<AddCircleOutlineIcon />}
                        size="small"
                        onClick={handleOpenQuotations}
                    >
                        Agregar nuevo campo
                    </Button>
                    {openQuotations && (
                        <AddMaterialQuotationModal
                            open={openQuotations}
                            handleClose={handleCloseQuotations}
                        />
                    )}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <Typography
                            fontSize={"24px"}
                            color={enerbitColors.primary.main}
                        >
                            Total:
                        </Typography>
                        <Chip
                            icon={<InfoOutlinedIcon fontSize="small" />}
                            label="Recuerda que el valor total con IVA incluido"
                            sx={{
                                color: enerbitColors.neutral.main,
                                backgroundColor: "white",
                            }}
                        />
                    </Box>
                    <Typography
                        fontWeight={"bold"}
                        fontSize={"28px"}
                        color={enerbitColors.primary.main}
                    >
                        {formattedTotal}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};
