import { Box, Grid, Typography } from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
    measurementType,
    meterLocation,
    meterSize,
    numberConnectionsElements,
    numberMetersElements,
    numberOfPhases,
} from "../../../const/meters";
import { installationType, inverterType } from "../../../const/varsSolar";
import type { IEditableSolarFormData } from "../../../interfaces/solar/IEditableSolar";
import { EditableSolarFormData } from "../../../schemas/solar/EditableSolarFormData";
import { useEmissionStoreSolar } from "../../../store/emission-solar";
import { FormItem } from "../../dynamic-form";
import { InverterTableSolar } from "./InverterTableSolar";

export const EditableSolarData = () => {
    const {
        formEditableData,
        updateEditableForm,
        enableButtonSendEmit,
        invertersSolar,
    } = useEmissionStoreSolar();

    const methods = useForm<IEditableSolarFormData>({
        resolver: zodResolver(EditableSolarFormData),
        defaultValues: {
            ...formEditableData,
        },
        mode: "onChange",
    });

    const {
        getValues,
        watch,
        trigger,
        setValue,
        formState: { isValid },
    } = methods;

    const validateSerial = invertersSolar?.find(
        (inverter) =>
            inverter.inverter_serial === "" || !inverter.inverter_serial,
    );

    useEffect(() => {
        if (isValid && !validateSerial) {
            enableButtonSendEmit(false);
        } else {
            enableButtonSendEmit(true);
        }
    }, [isValid, invertersSolar]);

    useEffect(() => {
        updateEditableForm(getValues());
    }, [
        watch("sfv_information"),
        watch("access_installation"),
        watch("features_solar_meter"),
    ]);

    useEffect(() => {
        trigger();
    }, []);

    useEffect(() => {
        if (getValues("sfv_information.epecista") !== "Otro") {
            setValue("sfv_information.epecista_new", "");
        }
    }, [watch("sfv_information.epecista")]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid container spacing={3}>
                    <FormItem
                        name=""
                        label="Informacion del sistema solar"
                        type="title"
                    />

                    <Grid item xs={6}>
                        <FormItem
                            name="sfv_information.installation_type"
                            label="Tipo de instalación"
                            type="select"
                            sx={{ xs: 12 }}
                            items={installationType}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormItem
                            name="sfv_information.inverter_type"
                            label="Tipo de inversor"
                            type="select"
                            sx={{ xs: 12 }}
                            items={inverterType}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormItem
                            name="sfv_information.epecista"
                            label="Epecista"
                            type="select"
                            sx={{ xs: 12 }}
                            items={[
                                { value: "RGST", text: "RGST" },
                                { value: "Greendipity", text: "Greendipity" },
                                { value: "Celsia", text: "Celsia" },
                                { value: "Rocasol", text: "Rocasol" },
                                { value: "Ninguno", text: "Ninguno" },
                                { value: "Otro", text: "Otro" },
                            ]}
                        />
                    </Grid>
                    {formEditableData?.sfv_information.epecista === "Otro" && (
                        <Grid item xs={6}>
                            <FormItem
                                name="sfv_information.epecista_new"
                                label="Agrega un nuevo epecista aquí!"
                                type="text"
                                sx={{ xs: 12 }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <FormItem
                            name="sfv_information.kwp_capacity_sfv"
                            label="Capacidad total (kWp)"
                            type="number"
                            sx={{ xs: 12 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormItem
                            name="sfv_information.dongle_exists"
                            label="¿El inversor tiene DONGLE?"
                            type="simple-switch"
                            tooltipInfo={
                                <Box
                                    sx={{
                                        p: "10px 10px 0px 10px",
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography>
                                        Valide si en las instalaciones el <br />
                                        inversor cuenta con un dispositivo
                                        similar:
                                    </Typography>
                                    <img
                                        src="https://ebdevmicrofront.blob.core.windows.net/resources/dongle.jpg"
                                        alt="DONGLE"
                                        style={{
                                            width: "200px",
                                            height: "200px",
                                        }}
                                    />
                                </Box>
                            }
                            sx={{ xs: 12 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormItem
                            name="sfv_information.dtu_exists"
                            label="¿El inversor tiene DTU?"
                            type="simple-switch"
                            tooltipInfo={
                                <Box
                                    sx={{
                                        p: "10px 10px 0px 10px",
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography>
                                        Valide si en las instalaciones el <br />
                                        inversor cuenta con un dispositivo
                                        similar:
                                    </Typography>
                                    <img
                                        src="https://ebdevmicrofront.blob.core.windows.net/resources/dtu.jpg"
                                        alt="DTU"
                                        style={{
                                            width: "200px",
                                            height: "200px",
                                        }}
                                    />
                                </Box>
                            }
                            sx={{ xs: 12 }}
                        />
                    </Grid>
                    <FormItem name="" label="Instalación solar" type="title" />
                    <Grid item xs={6}>
                        <FormItem
                            name="access_installation.smartbit_meter_integration"
                            label="Integración del medidor y/o smartbit"
                            type="text-area"
                            sx={{ xs: 12 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormItem
                            name="access_installation.de_energization_energy_network"
                            label="Desnergización red eléctrica"
                            type="text-area"
                            sx={{ xs: 12 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormItem
                            name="access_installation.de_energization_energy_inverter"
                            label="Desnergización del sistema solar"
                            type="text-area"
                            sx={{ xs: 12 }}
                        />
                    </Grid>
                    <FormItem
                        name=""
                        label="Características medidor solar"
                        type="title"
                    />
                    <Grid item xs={6}>
                        <FormItem
                            name="features_solar_meter.meter_location"
                            type="select"
                            label="Ubicación del medidor"
                            items={meterLocation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormItem
                            name="features_solar_meter.meter_high"
                            type="number"
                            label="Altura a la que se encuentra el medidor instalado (cms)"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormItem
                            name="features_solar_meter.measurement_type"
                            type="select"
                            label="Tipo de medición"
                            items={measurementType}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormItem
                            name="features_solar_meter.meter_size"
                            type="select"
                            label="Tamaño de medidor a instalar"
                            items={meterSize}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormItem
                            name="features_solar_meter.number_of_phases"
                            type="select"
                            label="Número de fases"
                            items={numberOfPhases}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormItem
                            name="features_solar_meter.number_meter_element"
                            type="select"
                            label="Número de elementos del medidor"
                            items={numberMetersElements}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormItem
                            name="features_solar_meter.number_connection_elements"
                            type="select"
                            label="Número de elementos de la conexión"
                            items={numberConnectionsElements}
                        />
                    </Grid>
                </Grid>
            </form>
            <br />
            {validateSerial && (
                <Grid item xs={12}>
                    <Box color="#f04438" textAlign="center">
                        Los seriales del inversor son requeridos
                    </Box>
                </Grid>
            )}
            <br />
            <InverterTableSolar />
        </FormProvider>
    );
};
