import { IconButton, darken, styled } from "@enerbit/base";

interface StyledIconButtonProps {
    color?:
        | "inherit"
        | "primary"
        | "secondary"
        | "error"
        | "success"
        | "warning"
        | "info"
        | "default";
}

export const StyledIconButton = styled(IconButton)<StyledIconButtonProps>(
    ({ theme, color }) => {
        let bgColor: string;
        let hoverColor: string;

        switch (color) {
            case "info":
                bgColor = "#007BFF"; // Substitute with your desired blue color
                hoverColor = darken(bgColor, 0.1); // Darken the color by 10%
                break;
            case "default":
                bgColor = "#6C757D"; // Substitute with your desired gray color
                hoverColor = darken(bgColor, 0.1); // Darken the color by 10%
                break;
            default:
                if (color && theme.palette[color]) {
                    bgColor = theme.palette[color].main;
                    hoverColor = theme.palette[color].dark;
                } else {
                    bgColor = theme.palette.primary.main; // Fallback color
                    hoverColor = theme.palette.primary.dark; // Fallback hover color
                }
                break;
        }

        return {
            borderRadius: "10px",
            width: 40,
            height: 40,
            color: "white",
            backgroundColor: bgColor,
            "& svg": {
                color: "white",
            },
            "&:hover": {
                backgroundColor: hoverColor,
            },
        };
    },
);
