import {
    Avatar,
    Box,
    CheckCircleIcon,
    ErrorOutlineIcon,
    Grid,
    LoadingButton,
    PictureAsPdfIcon,
    Tooltip,
    Typography,
    WarningIcon,
    enerbitColors,
    useSnackbar,
} from "@enerbit/base";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PendingIcon from "@mui/icons-material/Pending";
import axios from "axios";
import moment from "moment";
import { type FC, useState } from "react";
import { loadFile } from "../../helpers";
import type { IConceptExtended } from "../../interfaces";

interface Props {
    concepts: IConceptExtended[];
}

export const DownloadItem: FC<Props> = ({ concepts }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    const authenticateFile = async (file_path: string) => {
        try {
            setIsLoading(true);
            const authenticateLink = await loadFile(file_path);
            setIsLoading(false);
            window.open(authenticateLink, "_blank");
        } catch (error) {
            if (axios.isAxiosError(error)) {
                enqueueSnackbar(
                    `Error al cargar el archivo: ${error.message}`,
                    {
                        variant: "error",
                    },
                );
            } else {
                enqueueSnackbar("Ha ocurrido un error desconocido.", {
                    variant: "error",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const sortedConcepts = concepts.sort((a, b) =>
        moment(b.created_at).diff(moment(a.created_at)),
    );

    return (
        <Grid container item xs={12} spacing={3}>
            {sortedConcepts.map((concept, index) => (
                <Grid item xs={12} key={concept.id}>
                    {index === 0 && (
                        <Grid item xs={12}>
                            <Typography color={enerbitColors.primary.main}>
                                Concepto Actual
                            </Typography>
                        </Grid>
                    )}
                    {index === 1 && (
                        <Grid item xs={12}>
                            <Typography color={enerbitColors.primary.main}>
                                Concepto Anterior
                            </Typography>
                        </Grid>
                    )}
                    <Box
                        sx={{
                            borderRadius: "16px",
                            backgroundColor: enerbitColors.neutral[100],
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            sx={{
                                py: 2,
                                px: 4,
                                display: "flex",
                                gap: 3,
                                alignItems: "center",
                            }}
                        >
                            <Avatar
                                sx={{ bgcolor: enerbitColors.primary.main }}
                            >
                                <PictureAsPdfIcon sx={{ color: "white" }} />
                            </Avatar>
                            <Typography
                                color={enerbitColors.neutral[700]}
                                fontWeight={"bold"}
                            >
                                {concept.title}
                            </Typography>
                            <Typography color={enerbitColors.neutral[700]}>
                                {moment(concept.created_at).format(
                                    "YYYY-MM-DD HH:mm A",
                                )}
                            </Typography>
                            {concept.form_data?.diagnostic?.status === "ok" && (
                                <Tooltip title="Viable">
                                    <CheckCircleIcon
                                        fontSize="small"
                                        color="success"
                                    />
                                </Tooltip>
                            )}
                            {concept.form_data?.diagnostic?.status ===
                                "pending" && (
                                <Tooltip title="Pendiente de adecuaciones">
                                    <WarningIcon
                                        fontSize="small"
                                        color="secondary"
                                    />
                                </Tooltip>
                            )}
                            {concept.form_data?.diagnostic?.status ===
                                "pending_quotation" && (
                                <Tooltip title="Pendiente de cotización">
                                    <PendingIcon
                                        fontSize="small"
                                        color="secondary"
                                    />
                                </Tooltip>
                            )}
                            {concept.form_data?.diagnostic?.status === "no" && (
                                <Tooltip title="No viable">
                                    <ErrorOutlineIcon
                                        fontSize="small"
                                        color="secondary"
                                    />
                                </Tooltip>
                            )}
                        </Box>
                        <LoadingButton
                            onClick={() => authenticateFile(concept.file_path)}
                            loading={isLoading}
                            color="secondary"
                            variant="contained"
                            size="large"
                            sx={{
                                borderStartStartRadius: "0px!important",
                                borderBottomLeftRadius: "0px!important",
                            }}
                        >
                            <OpenInNewIcon />
                        </LoadingButton>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};
