import { api } from "@enerbit/base";
import type {
    ICurrentTransformer,
    IDocumentaryComplianceItem,
    IElectricalBox,
    IElectricalConductors,
    IInputDataFormData,
    ITerminal,
    IVoltageTransformer,
} from "../../interfaces";

interface IWiresCompliancesType {
    type: keyof IElectricalConductors;
    name: string;
}

export const generateDocumentaryCompliance = async (
    form_data: IInputDataFormData,
): Promise<IDocumentaryComplianceItem[]> => {
    const commonCompliancePromises = [
        getWiresCompliances(form_data.electrical_conductors),
        getTerminalsCompliance(form_data.terminals),
    ];

    let measurementSpecificPromises: Promise<IDocumentaryComplianceItem[]>[] =
        [];

    switch (form_data.main_meter?.measurement_type) {
        case "indirecta":
            measurementSpecificPromises = [
                ...commonCompliancePromises,
                getTTCompliances(form_data.voltage_transformer),
                getTCCompliances(form_data.current_transformer),
                getTTCalibration(form_data.voltage_transformer),
                getTCCalibration(form_data.current_transformer),
                getCellsCompliance(form_data.electrical_boxes),
            ];
            break;
        case "semidirecta":
            measurementSpecificPromises = [
                ...commonCompliancePromises,
                getTCCompliances(form_data.current_transformer),
                getTTCalibration(form_data.voltage_transformer),
                getCellsCompliance(form_data.electrical_boxes),
            ];
            break;
        default:
            measurementSpecificPromises = [
                getCellsCompliance(form_data.electrical_boxes),
            ];
            break;
    }

    const docs: IDocumentaryComplianceItem[] = await Promise.all(
        measurementSpecificPromises,
    ).then((results) => results.flat());

    return docs;
};

export const getWiresCompliances = async (
    electrical_conductors?: IElectricalConductors | null,
): Promise<IDocumentaryComplianceItem[]> => {
    const response = [];

    const types: IWiresCompliancesType[] = [
        { type: "voltage_signal", name: "Voltaje" },
        { type: "current_signal", name: "Corriente" },
    ];

    for (const { type, name } of types) {
        const signal = electrical_conductors?.[type];
        if (
            signal &&
            typeof signal === "object" &&
            "gauge" in signal &&
            signal.gauge?.value
        ) {
            const certificateResponse = await api.get(
                "/inventory-documentation/wires-compliances-certificate?page=0&size=50",
                {
                    params: {
                        material: signal.material,
                        gauge: signal.gauge.value,
                    },
                },
            );
            response.push({
                name: `Certificado de conformidad producto cableado: ${name}`,
                status: certificateResponse.data.items.length > 0,
            });
        }
    }

    return response;
};

export const getTerminalsCompliance = async (
    terminals?: ITerminal[] | null,
): Promise<IDocumentaryComplianceItem[]> => {
    const compliancePromises =
        terminals?.map(async (item, index) => {
            const response = await api.get(
                "/inventory-documentation/borners-compliance-certificate?page=0&size=50",
                {
                    params: { brand: item.manufacturer },
                },
            );

            return {
                name: `Certificado de conformidad producto bornera de pruebas n° ${
                    index + 1
                }`,
                status: response.data.items.length > 0,
            };
        }) ?? [];

    const docs = await Promise.all(compliancePromises);

    return docs;
};

export const getTTCompliances = async (
    data?: IVoltageTransformer | null,
): Promise<IDocumentaryComplianceItem[]> => {
    const phases = ["r_phase", "s_phase", "t_phase"] as const;
    const response = [];

    for (const phase of phases) {
        const phaseData = data?.[phase];
        if (phaseData?.manufacturer) {
            const certificateResponse = await api.get(
                "/inventory-documentation/tt-compliances-certificate?page=0&size=50",
                {
                    params: { brand: phaseData.manufacturer },
                },
            );
            response.push({
                name: `Certificado conformidad producto transformadores de tensión: Fase ${phase
                    .charAt(0)
                    .toUpperCase()}`,
                status: certificateResponse.data.items.length > 0,
            });
        }
    }

    return response;
};

export const getTCCompliances = async (
    data?: ICurrentTransformer | null,
): Promise<IDocumentaryComplianceItem[]> => {
    const phases = ["r_phase", "s_phase", "t_phase"] as const;
    const response = [];

    for (const phase of phases) {
        const phaseData = data?.[phase];
        if (phaseData?.manufacturer) {
            const certificateResponse = await api.get(
                "/inventory-documentation/tc-compliances-certificate?page=0&size=50",
                {
                    params: { brand: phaseData.manufacturer },
                },
            );
            response.push({
                name: `Certificado conformidad producto transformadores de corriente: Fase ${phase
                    .charAt(0)
                    .toUpperCase()}`,
                status: certificateResponse.data.items.length > 0,
            });
        }
    }

    return response;
};

export const getTCCalibration = async (
    data?: ICurrentTransformer | null,
): Promise<IDocumentaryComplianceItem[]> => {
    const response = [];
    const phases = ["r_phase", "s_phase", "t_phase"] as const;

    for (const phase of phases) {
        const phaseData = data?.[phase];
        if (phaseData?.serial_number?.value) {
            const certificateResponse = await api.get(
                "/inventory-documentation/tc-calibrations-certificate?page=0&size=50",
                {
                    params: { number: phaseData.serial_number.value },
                },
            );
            response.push({
                name: `Certificado calibración transformadores de corriente: Fase ${phase
                    .charAt(0)
                    .toUpperCase()}`,
                status: certificateResponse.data.items.length > 0,
            });
        }
    }

    return response;
};

export const getTTCalibration = async (
    voltage_transformer?: IVoltageTransformer | null,
): Promise<IDocumentaryComplianceItem[]> => {
    const response = [];
    const phases = ["r_phase", "s_phase", "t_phase"] as const;

    for (const phase of phases) {
        const phaseData = voltage_transformer?.[phase];
        if (phaseData?.serial_number?.value) {
            const certificateResponse = await api.get(
                "/inventory-documentation/tt-calibrations-certificate?page=0&size=50",
                {
                    params: { number: phaseData.serial_number.value },
                },
            );
            response.push({
                name: `Certificado calibración transformadores de tensión: Fase ${phase
                    .charAt(0)
                    .toUpperCase()}`,
                status: certificateResponse.data.items.length > 0,
            });
        }
    }

    return response;
};

export const getCellsCompliance = async (
    electrical_boxes?: IElectricalBox[] | null,
): Promise<IDocumentaryComplianceItem[]> => {
    const response = [];

    if (electrical_boxes && electrical_boxes.length > 0) {
        const firstBox = electrical_boxes[0];
        if (firstBox.manufacturer.value) {
            const cells = await api.get(
                "/inventory-documentation/cells-compliance-certificate?page=0&size=50",
                {
                    params: { brand: firstBox.manufacturer.value },
                },
            );
            response.push({
                name: "Certificado de conformidad producto caja o gabinete",
                status: cells.data.items.length > 0,
            });
        }
    }

    return response;
};
