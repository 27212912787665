import {
    CancelIcon,
    CheckCircleIcon,
    Grid,
    type GridColDef,
    type GridRenderCellParams,
    Tooltip,
} from "@enerbit/base";
import type { IMeasurementSystemItem } from "../../interfaces";
import { useEmissionStore } from "../../store";
import { EmissionTable } from "./EmissionTable";

const columns: GridColDef[] = [
    {
        field: "field",
        headerClassName: "custom-header",
        headerName: "Nombre del campo",
        flex: 2,
        align: "left",
        headerAlign: "left",
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "currentValue",
        headerClassName: "custom-header",
        headerName: "Valor situación actual",
        flex: 1,
        align: "left",
        headerAlign: "left",
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "realValue",
        headerClassName: "custom-header",
        headerName: "Valor situación real",
        flex: 1,
        align: "left",
        headerAlign: "left",
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "verification",
        headerClassName: "custom-header",
        headerName: "",
        sortable: false,
        align: "right",
        headerAlign: "right",
        disableColumnMenu: true,
        width: 20,
        renderCell: (
            params: GridRenderCellParams<string, IMeasurementSystemItem>,
        ) => {
            return (
                <Tooltip title={params.value ? "Verificado" : "No verificado"}>
                    {params.value ? (
                        <CheckCircleIcon fontSize="small" color="success" />
                    ) : (
                        <CancelIcon fontSize="small" color="error" />
                    )}
                </Tooltip>
            );
        },
    },
];

export const MeasurementSystem = () => {
    const { measurementSystem } = useEmissionStore();

    if (!measurementSystem) {
        return null;
    }

    const {
        mainMeter,
        optionalMeter,
        currentTransformer,
        voltageTransformer,
        electricalConductors,
    } = measurementSystem;

    return (
        <Grid container spacing={3}>
            {mainMeter.length > 0 && (
                <EmissionTable
                    rows={
                        mainMeter.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Medida principal"
                    columns={columns}
                />
            )}
            {optionalMeter.length > 0 && (
                <EmissionTable
                    rows={optionalMeter.map((item, index) => ({
                        ...item,
                        id: index,
                    }))}
                    title="Medidor de respaldo / testigo"
                    columns={columns}
                />
            )}
            {currentTransformer.length > 0 && (
                <EmissionTable
                    rows={currentTransformer.map((item, index) => ({
                        ...item,
                        id: index,
                    }))}
                    title="Transformador de corriente"
                    columns={columns}
                />
            )}
            {voltageTransformer.length > 0 && (
                <EmissionTable
                    rows={voltageTransformer.map((item, index) => ({
                        ...item,
                        id: index,
                    }))}
                    title="Transformador de tensión"
                    columns={columns}
                />
            )}
            {electricalConductors.length > 0 && (
                <EmissionTable
                    rows={electricalConductors.map((item, index) => ({
                        ...item,
                        id: index,
                    }))}
                    title="Conductores"
                    columns={columns}
                />
            )}
        </Grid>
    );
};
