import { Box, type BoxProps, Tab, Tabs, styled } from "@enerbit/base";

interface StyledTabProps {
    label: string | JSX.Element;
}

export const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    color: "#344054",
    fontWeight: "400",
    textTransform: "none",
    marginRight: theme.spacing(1),
    "&.Mui-selected": {
        color: "#101828",
        fontWeight: "700",
    },
    div: {
        height: "40px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 12px",
        gap: "8px",
    },
    "&.Mui-selected div": {
        height: "40px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 12px",
        gap: "8px",
        background: "rgba(27, 27, 30, 0.08)",
        borderRadius: "8px",
    },
}));

interface StyledTabsProps {
    className?: string | undefined;
    value: number;
    onChange: (_event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => {
    return (
        <Tabs
            {...props}
            value={props.value}
            onChange={props.onChange}
            classes={{
                root: props.className,
                flexContainer: "flexContainer",
                indicator: "indicator",
            }}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            TabIndicatorProps={{ children: <span /> }}
        />
    );
})({
    "& .indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        "& > span": {
            maxWidth: "60%",
            width: "100%",
            backgroundColor: "#FF7705",
        },
    },
    "& .flexContainer": {
        // width:'fit-content'
    },
    "&.MuiButtonBase .MuiTab-root": {
        padding: "0px",
    },
});

interface Props extends BoxProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const TabPanel = ({ children, value, index }: Props) => {
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            p={4}
        >
            {value === index && <Box>{children}</Box>}
        </Box>
    );
};
