import {
    AddCircleOutlineIcon,
    Box,
    Button,
    CloseIcon,
    DeleteIcon,
    EditIcon,
    Grid,
    type GridColDef,
    type GridRenderCellParams,
    IconButton,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Tooltip,
    Typography,
    enerbitColors,
    esES,
    useGridApiContext,
} from "@enerbit/base";
import { useState } from "react";
import { StyledDataGrid } from "../../../common";
import { formatPrice } from "../../../helpers";
import type { IMaterial, IMaterialExtended } from "../../../interfaces";
import { useEmissionStore } from "../../../store";
import { useEmissionStoreSolar } from "../../../store/emission-solar";
import { AddMaterialModal } from "../AddMaterialModal";

export const MaterialLaborTableSolar = () => {
    const [editingRow, setEditingRow] = useState<string | null>(null);

    const [openMeter, setOpenMeter] = useState(false);
    const [openSmartbit, setOpenSmartbit] = useState(false);

    const handleMeter = () => {
        setOpenMeter(!openMeter);
    };

    const handleSmartbit = () => {
        setOpenSmartbit(!openSmartbit);
    };
    const { materialsSolar, deleteMaterialSolar, updateQuantityMaterialSolar } =
        useEmissionStoreSolar();
    const { listQuantities } = useEmissionStore();

    const getColumns = (key: keyof typeof materialsSolar): GridColDef[] => {
        const columns: GridColDef[] = [
            {
                field: "name",
                headerClassName: "custom-header",
                headerName: "Nombre del campo",
                flex: 2,
                align: "left",
                headerAlign: "left",
                sortable: false,
                disableColumnMenu: true,
            },
            {
                field: "quantity",
                headerClassName: "custom-header",
                headerName: "Cantidad",
                editable: true,
                flex: 1,
                type: "number",
                align: "left",
                headerAlign: "left",
                sortable: false,
                disableColumnMenu: true,
                renderEditCell: (params) => {
                    const { id, value, field } = params;
                    const apiRef = useGridApiContext();

                    const handleValueChange = (event: SelectChangeEvent) => {
                        const newValue = event.target.value;
                        apiRef.current.setEditCellValue({
                            id,
                            field,
                            value: newValue,
                        });
                        apiRef.current.setCellMode(id, field, "view");
                        updateQuantityMaterialSolar(
                            params.row.id,
                            Number(newValue),
                            key,
                        );
                    };
                    return (
                        <Select
                            fullWidth
                            value={value}
                            onChange={handleValueChange}
                            displayEmpty
                        >
                            {listQuantities.map((item, index) => (
                                <MenuItem value={item} key={index.toString()}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    );
                },
            },
            {
                field: "charge",
                headerClassName: "custom-header",
                headerName: "Valor",
                type: "number",
                flex: 1,
                align: "left",
                headerAlign: "left",
                sortable: false,
                disableColumnMenu: true,
                renderCell: (
                    params: GridRenderCellParams<number, IMaterial, unknown>,
                ) => {
                    return params.value ? formatPrice(params.value) : "";
                },
            },
            {
                field: "subtotal",
                headerClassName: "custom-header",
                headerName: "Subtotal",
                flex: 1,
                align: "left",
                headerAlign: "left",
                sortable: false,
                disableColumnMenu: true,
                renderCell: (
                    params: GridRenderCellParams<number, IMaterial, unknown>,
                ) => {
                    return params.value ? formatPrice(params.value) : "";
                },
            },
            {
                field: "id",
                headerName: "",
                headerClassName: "custom-header",
                align: "left",
                headerAlign: "left",
                sortable: false,
                disableColumnMenu: true,
                renderCell: (
                    params: GridRenderCellParams<
                        string,
                        IMaterialExtended,
                        unknown
                    >,
                ) => {
                    const { id } = params;
                    const apiRef = useGridApiContext();

                    if (
                        editingRow !== id &&
                        apiRef.current.getRowMode(id) === "edit"
                    ) {
                        apiRef.current.setRowMode(editingRow as string, "view");
                    }

                    const handleEdit = () => {
                        setEditingRow(id as string);
                        apiRef.current.setRowMode(id, "edit");
                    };

                    const handleCancel = () => {
                        setEditingRow(null);
                        apiRef.current.setRowMode(id, "view");
                    };

                    return (
                        <Box
                            width={"100%"}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                gap: 2,
                            }}
                        >
                            {apiRef.current.getRowMode(id) === "view" ? (
                                <Tooltip title="Editar cantidad">
                                    <IconButton
                                        size="small"
                                        onClick={handleEdit}
                                        color="primary"
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Cancelar edición">
                                    <IconButton
                                        size="small"
                                        onClick={handleCancel}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title={"Eliminar"}>
                                <IconButton
                                    color="error"
                                    size="small"
                                    onClick={() =>
                                        params.value
                                            ? deleteMaterialSolar(
                                                  params.value,
                                                  key,
                                              )
                                            : null
                                    }
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        ];
        return columns;
    };

    return (
        <Grid item xs={12}>
            <Box
                sx={{
                    width: "100%",
                    "& .custom-header": {
                        backgroundColor: "#EFE9FB",
                        "&:first-child": {
                            borderTopLeftRadius: "12px",
                            borderBottomLeftRadius: "12px",
                        },
                        "&:last-child": {
                            borderTopRightRadius: "12px",
                            borderBottomRightRadius: "12px",
                        },
                    },
                }}
            >
                <Grid item xs={12}>
                    <Typography
                        fontWeight={"bold"}
                        color={enerbitColors.primary.main}
                        sx={{
                            mt: "10px",
                            mb: "10px",
                        }}
                    >
                        Materiales medidor
                    </Typography>
                </Grid>
                <StyledDataGrid
                    sx={{
                        "& .MuiDataGrid-virtualScrollerContent": {
                            height: materialsSolar.materials_meter.length
                                ? "auto!important"
                                : "100px!important",
                        },
                    }}
                    columns={getColumns("materials_meter")}
                    rows={materialsSolar.materials_meter}
                    pageSize={100}
                    autoHeight
                    hideFooter
                    paginationMode="client"
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    editMode="row"
                />
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            mt: "10px",
                        }}
                    >
                        <Button
                            color="primary"
                            variant="outlined"
                            startIcon={<AddCircleOutlineIcon />}
                            size="small"
                            onClick={handleMeter}
                        >
                            Agregar nuevo campo
                        </Button>
                        {openMeter && (
                            <AddMaterialModal
                                keyDataSolar={"materials_meter"}
                                isSolar={true}
                                open={openMeter}
                                handleClose={handleMeter}
                            />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        fontWeight={"bold"}
                        color={enerbitColors.primary.main}
                        sx={{
                            mt: "10px",
                            mb: "10px",
                        }}
                    >
                        Materiales smartbit
                    </Typography>
                </Grid>
                <StyledDataGrid
                    sx={{
                        "& .MuiDataGrid-virtualScrollerContent": {
                            height: materialsSolar.materials_smartbit.length
                                ? "auto!important"
                                : "100px!important",
                        },
                    }}
                    columns={getColumns("materials_smartbit")}
                    rows={materialsSolar.materials_smartbit}
                    pageSize={100}
                    autoHeight
                    hideFooter
                    paginationMode="client"
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    editMode="row"
                />
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            mt: "10px",
                        }}
                    >
                        <Button
                            color="primary"
                            variant="outlined"
                            startIcon={<AddCircleOutlineIcon />}
                            size="small"
                            onClick={handleSmartbit}
                        >
                            Agregar nuevo campo
                        </Button>
                        {openSmartbit && (
                            <AddMaterialModal
                                keyDataSolar={"materials_smartbit"}
                                isSolar={true}
                                open={openSmartbit}
                                handleClose={handleSmartbit}
                            />
                        )}
                    </Box>
                </Grid>
            </Box>
        </Grid>
    );
};
