import { create } from "zustand";
import type { IViabilityDiagnosticStore } from "../interfaces";

const initialState = {
    valueTab: 0,
};

export const useViabilityDiagnosticStore = create<IViabilityDiagnosticStore>()(
    (set) => ({
        ...initialState,
        setValueTab: (value) => set({ valueTab: value }),
    }),
);
