import {
    Box,
    Button,
    Grid,
    type GridColDef,
    type GridRenderCellParams,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Stack,
    esES,
} from "@enerbit/base";
import { useEffect } from "react";
import { StyledDataGrid } from "../../common";
import { OrderTypes } from "../../enums/solar/ordersType";
import type { IRespPendingReview } from "../../interfaces";
import {
    usePendingReviewStore,
    useTableStore,
    useViabilityDiagnosticStore,
} from "../../store";
import { PendingReviewPagination } from "./PendingReviewPagination";

export const PendingReview = () => {
    const {
        getPendingDiagnostics,
        page,
        size,
        filter,
        listDiagnostics,
        loadingPendingReview,
    } = usePendingReviewStore();

    const { setValueTab } = useViabilityDiagnosticStore();
    const { setSearch, setName, openModal, diagnosticType, setDiagnosticType } =
        useTableStore();

    const handleFilterChange = (e: SelectChangeEvent<string | null>): void => {
        const value = e.target.value as OrderTypes;
        value && setDiagnosticType(value);
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerClassName: "custom-header",
            headerName: "Nombre",
            flex: 1,
            align: "left",
            headerAlign: "left",
            sortable: false,
            disableColumnMenu: true,
            minWidth: 75,
        },
        {
            field: "address",
            headerClassName: "custom-header",
            headerName: "Dirección",
            flex: 1,
            align: "left",
            headerAlign: "left",
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
        },
        {
            field: "odoo_url",
            headerClassName: "custom-header",
            headerName: "Acciones",
            sortable: false,
            flex: 1,
            align: "left",
            headerAlign: "left",
            disableColumnMenu: true,
            minWidth: 200,
            renderCell: (
                params: GridRenderCellParams<string, IRespPendingReview>,
            ) => {
                const handleOpenOdoo = () => {
                    window.open(params.value, "_blank");
                };

                const handleReview = () => {
                    setValueTab(0);
                    openModal();
                    setSearch(params.row.email);
                    setName(params.row.name);
                };

                return (
                    <Box sx={{ display: "flex" }}>
                        <Stack direction={"row"} spacing={2}>
                            <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                onClick={handleOpenOdoo}
                            >
                                Odoo
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={handleReview}
                            >
                                Revisar
                            </Button>
                        </Stack>
                    </Box>
                );
            },
        },
    ];

    useEffect(() => {
        getPendingDiagnostics(page, size, filter, diagnosticType);
    }, [page, size, diagnosticType, filter, getPendingDiagnostics]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={3} lg={3} sx={{ pl: "10px" }}>
                <Box sx={{ mb: "10px" }}>Tipo de orden</Box>
                <Box>
                    <Select
                        sx={{
                            borderRadius: "14px",
                        }}
                        fullWidth
                        value={diagnosticType}
                        onChange={handleFilterChange}
                    >
                        <MenuItem value={OrderTypes.conventional}>
                            Convencional
                        </MenuItem>
                        <MenuItem value={OrderTypes.solar}>Solar</MenuItem>
                    </Select>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box
                    sx={{
                        width: "100%",
                        "& .custom-header": {
                            backgroundColor: "#EFE9FB",
                            "&:first-of-type": {
                                borderTopLeftRadius: "12px",
                                borderBottomLeftRadius: "12px",
                            },
                            "&:last-child": {
                                borderTopRightRadius: "12px",
                                borderBottomRightRadius: "12px",
                            },
                        },
                    }}
                >
                    <StyledDataGrid
                        sx={{
                            "& .MuiDataGrid-virtualScrollerContent": {
                                height:
                                    listDiagnostics.length > 0
                                        ? "auto!important"
                                        : "100px!important",
                            },
                            "& .MuiDataGrid-row": {
                                minHeight: "52px!important",
                                py: 1,
                            },
                        }}
                        getRowHeight={() => "auto"}
                        columns={columns}
                        rows={listDiagnostics.map((item, index) => {
                            return {
                                ...item,
                                id: index,
                            };
                        })}
                        loading={loadingPendingReview}
                        pageSize={5}
                        rowsPerPageOptions={[]}
                        rowCount={listDiagnostics.length}
                        autoHeight
                        paginationMode="server"
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        components={{
                            Pagination: PendingReviewPagination,
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};
