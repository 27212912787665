import { z } from "zod";

export const createMaterialSchema = z.object({
    selectedCategory: z.string().min(1, "La categoría es requerida."),
    selectedMaterial: z.string().min(1, "El material es requerido."),
    selectedQuantity: z.number().min(1, "La cantidad es requerida."),
});

export const createMaterialQuotationSchema = z.object({
    material: z.string().min(1, "El nombre del material es requerido."),
    quantity: z
        .string()
        .min(1, "La cantidad es requerida.")
        .refine((value) => !Number.isNaN(Number(value)) && Number(value) > 0, {
            message: "La cantidad debe ser un número positivo.",
        }),
    description: z.string().min(1, "La descripción del material es requerida."),
});
