import {
    Box,
    Chip,
    Grid,
    InfoOutlinedIcon,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import { ViabilityError, ViabilityStatus } from "..";
import { formatPrice } from "../../../helpers";
import { calculateTotal } from "../../../helpers/material-labor";
import { useEmissionStore } from "../../../store";
import { useEmissionStoreSolar } from "../../../store/emission-solar";
import { MaterialLaborTableSolar } from "./MaterialLaborTableSolar";

export const MaterialLaborSolar = () => {
    const { installation } = useEmissionStore();
    const { materialsSolar } = useEmissionStoreSolar();
    const totalMeter = calculateTotal(materialsSolar.materials_meter);
    const totalSmartbit = calculateTotal(materialsSolar.materials_smartbit);
    const total = totalMeter + totalSmartbit;
    const formattedTotal = formatPrice(total);

    return (
        <Grid container spacing={3}>
            {installation ? (
                <ViabilityStatus
                    status={total <= installation.totalInstallation}
                />
            ) : (
                <ViabilityError />
            )}
            <MaterialLaborTableSolar />
            <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <Typography
                            fontSize={"24px"}
                            color={enerbitColors.primary.main}
                        >
                            Total:
                        </Typography>
                        <Chip
                            icon={<InfoOutlinedIcon fontSize="small" />}
                            label="Recuerda que el valor total con IVA incluido"
                            sx={{
                                color: enerbitColors.neutral.main,
                                backgroundColor: "white",
                            }}
                        />
                    </Box>
                    <Typography
                        fontWeight={"bold"}
                        fontSize={"28px"}
                        color={enerbitColors.primary.main}
                    >
                        {formattedTotal}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};
