export const getConsumptionProjection = (value: number) => {
    return value * 720 * 0.8 * 0.9;
};

export const getVoltageLevel = (serviceVoltage: number) => {
    if (serviceVoltage >= 0 && serviceVoltage < 0.025) {
        return "MBT";
    }
    if (serviceVoltage >= 0.025 && serviceVoltage < 1) {
        return "1";
    }
    if (serviceVoltage >= 1 && serviceVoltage < 30) {
        return "2";
    }
    if (serviceVoltage >= 30 && serviceVoltage < 57.5) {
        return "3";
    }
    if (serviceVoltage >= 57.5 && serviceVoltage < 220) {
        return "4";
    }
    return "EAT";
};

export const getInstallationCapacityType = (installedCapacity: number) => {
    //KVA kILO VOLTIO AMPERIOS (POtencia)
    if (installedCapacity >= 0 && installedCapacity < 10) {
        return 5;
    }
    if (installedCapacity >= 10 && installedCapacity < 100) {
        return 4;
    }
    if (installedCapacity >= 100 && installedCapacity < 1000) {
        return 3;
    }
    if (installedCapacity >= 1000 && installedCapacity < 30000) {
        return 2;
    }
    return 1;
};

export const getAverageConsumeType = (averageConsume: string) => {
    //KW/h
    const currentAverage = Number(averageConsume) || 0;

    if (currentAverage >= 0 && currentAverage < 5000) {
        return 5;
    }
    if (currentAverage >= 5000 && currentAverage < 50000) {
        return 4;
    }
    if (currentAverage >= 50000 && currentAverage < 500000) {
        return 3;
    }
    if (currentAverage >= 500000 && currentAverage < 15000000) {
        return 2;
    }
    return 1;
};
