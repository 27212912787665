import { DialogContent, Grid } from "@enerbit/base";
import type { FC } from "react";
import { InstallationCostContainer } from ".";
import { StyledDialog, StyledDialogTitle } from "../../common";
import { useEmissionStore } from "../../store";

interface Props {
    open: boolean;
    handleClose: () => void;
}

export const InstallationCostsModal: FC<Props> = ({ open, handleClose }) => {
    const installationCosts = useEmissionStore(
        (state) => state.installationCosts,
    );

    return (
        <StyledDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="lg"
            fullWidth
        >
            <StyledDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                Costos de instalación enerBit 2024
            </StyledDialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    {installationCosts.map(
                        ({ category, installations }, index) => {
                            return (
                                <InstallationCostContainer
                                    key={index.toString()}
                                    category={category}
                                    installations={installations}
                                />
                            );
                        },
                    )}
                </Grid>
            </DialogContent>
        </StyledDialog>
    );
};
