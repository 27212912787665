import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    Typography,
} from "@enerbit/base";
import type { Dispatch, FC, SetStateAction } from "react";
import type { IPictureArray } from "../../interfaces";

interface Props {
    image: IPictureArray;
    index: number;
    setIndex: Dispatch<SetStateAction<number>>;
}

export const PhotoCard: FC<Props> = ({ image, index, setIndex }) => {
    const { image_title, image_url } = image;
    return (
        <Grid item xs={6} md={4} lg={3}>
            <Card
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {image_url ? (
                    <CardActionArea onClick={() => setIndex(index)}>
                        <CardMedia
                            component="img"
                            height="150"
                            src={image_url}
                            alt={image_title}
                        />
                    </CardActionArea>
                ) : (
                    <CardMedia
                        component="img"
                        height="150"
                        src={
                            "https://ebdevmicrofront.blob.core.windows.net/resources/not-found-image.webp"
                        }
                        alt={image_title}
                    />
                )}
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom component="div">
                        {image_url
                            ? image_title
                            : `${image_title} (Imagen no cargada)`}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
};
