import { NotiProvider, ThemeConfig, api, initEnviroment } from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import { SWRConfig } from "swr";
import { MainLayout } from "./layouts";
import { ViabilityDiagnostic } from "./pages/ViabilityDiagnostic";

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
    geopoliticsBaseUrl: process.env.REACT_APP_BASE_GEOPOLITICS_URL,
});

const fetcher = (url: string) => api.get(url).then((res) => res.data);

export default hot(function Root() {
    return (
        <ThemeConfig>
            <NotiProvider>
                <SWRConfig
                    value={{
                        fetcher,
                        keepPreviousData: true,
                    }}
                >
                    <MainLayout>
                        <ViabilityDiagnostic />
                    </MainLayout>
                </SWRConfig>
            </NotiProvider>
        </ThemeConfig>
    );
});
