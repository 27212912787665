import { create } from "zustand";
import { IDiagnosticStore } from "../interfaces/diagnostic";

const initialState = {
    diagnostics: [],
    page: 0,
    size: 10,
    selectedStatus: "",
};

export const useDiagnosticsStore = create<IDiagnosticStore>((set) => ({
    ...initialState,
    setDiagnostics: (data) => set({ diagnostics: data }),
    setPage: (page) => set({ page }),
    setSize: (size) => set({ size }),
    setSelectedStatus: (selectedStatus) => set({ selectedStatus }),
}));
