import {
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
} from "@enerbit/base";
import { type FC, useEffect, useState } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import type { IDynamicForm } from "../../../interfaces";

export const SelectForm: FC<IDynamicForm> = ({
    name,
    label,
    items,
    fetchItems,
    sx,
    props,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const [selectItems, setSelectItems] = useState(items || []);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchAndSetItems = async () => {
            if (fetchItems) {
                setIsLoading(true);
                const fetchedItems = await fetchItems();
                setSelectItems(fetchedItems);
                setIsLoading(false);
            }
        };
        fetchAndSetItems();
    }, [fetchItems]);

    if (!name) return null;

    return (
        <Grid item xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
            <InputLabel>{label}</InputLabel>
            <FormControl fullWidth>
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            id={`${name}`}
                            disabled={props?.disabled}
                            displayEmpty
                            readOnly={isLoading}
                            error={!!get(errors, name)}
                            sx={{
                                "& fieldset": { borderRadius: "14px" },
                                pr: isLoading ? 3 : 0,
                                height: "52px",
                            }}
                            endAdornment={
                                isLoading && (
                                    <InputAdornment position="start">
                                        <CircularProgress
                                            size={20}
                                            thickness={6}
                                        />
                                    </InputAdornment>
                                )
                            }
                        >
                            {selectItems.map(
                                ({ text, value, icon: Icon, color }, index) => (
                                    <MenuItem
                                        value={value.toString()}
                                        key={index.toString()}
                                    >
                                        <ListItem>
                                            {Icon && (
                                                <ListItemIcon
                                                    sx={{ height: "100%" }}
                                                >
                                                    <Icon
                                                        fontSize="small"
                                                        color={color}
                                                    />
                                                </ListItemIcon>
                                            )}
                                            <ListItemText>{text}</ListItemText>
                                        </ListItem>
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    )}
                />
                {!!get(errors, name) && (
                    <FormHelperText error>
                        {get(errors, name).message}
                    </FormHelperText>
                )}
            </FormControl>
        </Grid>
    );
};
