import {
    Box,
    Button,
    CheckCircleIcon,
    DialogContent,
    Divider,
    ErrorOutlineIcon,
    Grid,
    Typography,
    WarningIcon,
    enerbitColors,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { StyledDialog, StyledDialogTitle } from "../../common";
import { OrderTypes } from "../../enums/solar/ordersType";
import type { IConceptExtended } from "../../interfaces";
import { useDownloadStore, useEmissionStore, useTableStore } from "../../store";
import { DownloadItem } from "../consult-download";

const statusDiagnosticMap = {
    ok: "Viable",
    pending: "Pendiente de adecuaciones",
    pending_quotation: "Pendiente de cotización",
    quoted: "Cotizado",
};

interface IStatusMap {
    ok: string;
    pending: string;
    pending_quotation: string;
    quoted: string;
}

interface IStatusIconMap {
    ok: React.ReactNode;
    pending: React.ReactNode;
    pending_quotation: React.ReactNode;
    quoted: React.ReactNode;
}

export const LastDiagnostic = () => {
    const { previous_diagnostic } = useEmissionStore();
    const { addedEntities, diagnosticType } = useTableStore();
    const { technical, business, getConcepts } = useDownloadStore();

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (addedEntities.length > 0) {
            getConcepts(
                addedEntities[0].id,
                diagnosticType === OrderTypes.solar,
            );
        }
    }, [addedEntities, diagnosticType, getConcepts]);

    if (!previous_diagnostic) {
        return null;
    }

    const { created_at, form_data } = previous_diagnostic;

    const statusIconMap: IStatusIconMap = {
        ok: <CheckCircleIcon color="success" sx={{ fontSize: "18px" }} />,
        quoted: <CheckCircleIcon color="success" sx={{ fontSize: "18px" }} />,
        pending: <WarningIcon color="warning" sx={{ fontSize: "18px" }} />,
        pending_quotation: (
            <WarningIcon color="warning" sx={{ fontSize: "18px" }} />
        ),
    };

    const StatusIcon = statusIconMap[
        form_data.diagnostic.status as keyof IStatusIconMap
    ] || <ErrorOutlineIcon color="error" sx={{ fontSize: "18px" }} />;
    return (
        <Grid item xs={12}>
            <Box
                sx={{
                    borderRadius: "12px",
                    backgroundColor: "#EEEBF4",
                    display: "flex",
                    justifyContent: "space-between",
                    px: 4,
                    py: 2,
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            color: enerbitColors.primary.main,
                            fontSize: "18px",
                            fontWeight: "bold",
                        }}
                    >
                        Último diagnóstico emitido
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                        <Typography>
                            Fecha: <br />{" "}
                            <b>{moment(created_at).format("YYYY-MM-DD")}</b>
                        </Typography>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />

                        <Typography>
                            Responsable: <br />{" "}
                            <b>{form_data.emited_by?.email}</b>
                        </Typography>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />
                        <Box>
                            Estado:
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "start",
                                    gap: 1,
                                }}
                            >
                                {StatusIcon}

                                <Typography sx={{ fontWeight: "bold" }}>
                                    {statusDiagnosticMap[
                                        form_data.diagnostic
                                            .status as keyof IStatusMap
                                    ] || "No viable"}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                        color="primary"
                        variant="outlined"
                        size="large"
                        onClick={handleOpen}
                    >
                        Ver detalle
                    </Button>
                </Box>
            </Box>
            <StyledDialog
                open={open}
                fullWidth
                onClose={handleClose}
                maxWidth="md"
            >
                <StyledDialogTitle onClose={handleClose} id={"id-confirm-edit"}>
                    Detalle del último diagnóstico emitido
                </StyledDialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", gap: 2 }}>
                                <Typography>
                                    Fecha: <br />{" "}
                                    <b>
                                        {moment(created_at).format(
                                            "YYYY-MM-DD",
                                        )}
                                    </b>
                                </Typography>
                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                />

                                <Typography>
                                    Responsable: <br />{" "}
                                    <b>{form_data.emited_by?.email}</b>
                                </Typography>
                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                />
                                <Box>
                                    Estado:
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "start",
                                            gap: 1,
                                        }}
                                    >
                                        {StatusIcon}

                                        <Typography sx={{ fontWeight: "bold" }}>
                                            {statusDiagnosticMap[
                                                form_data.diagnostic
                                                    .status as keyof IStatusMap
                                            ] || "No viable"}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                color={enerbitColors.primary.main}
                                fontSize={"18px"}
                            >
                                Información diagnóstico emitido
                            </Typography>
                            <Typography>
                                Medidor principal:{" "}
                                <b>{form_data.diagnostic.main_meter}</b>
                            </Typography>
                            <Typography>
                                Bloque de pruebas:{" "}
                                <b>{form_data.diagnostic.test_block}</b>
                            </Typography>
                            <Typography>
                                Comunicación:{" "}
                                <b>{form_data.diagnostic.comunication}</b>
                            </Typography>
                            {form_data.diagnostic.current_transformer && (
                                <Typography>
                                    Transformador de corriente:{" "}
                                    <b>
                                        {
                                            form_data.diagnostic
                                                .current_transformer
                                        }
                                    </b>
                                </Typography>
                            )}
                            {form_data.diagnostic.voltage_transformer && (
                                <Typography>
                                    Transformador de tensión:{" "}
                                    <b>
                                        {
                                            form_data.diagnostic
                                                .voltage_transformer
                                        }
                                    </b>
                                </Typography>
                            )}
                            <Typography>
                                Paneles o cajas:{" "}
                                <b>{form_data.diagnostic.box}</b>
                            </Typography>
                            <Typography>
                                Cable de control:{" "}
                                <b>{form_data.diagnostic.control_wire}</b>
                            </Typography>
                            <Typography>
                                Otro: <b>{form_data.diagnostic.other}</b>
                            </Typography>
                            <Typography>
                                Apertura: <b>{form_data.diagnostic.opening}</b>
                            </Typography>
                        </Grid>
                        <DownloadItem
                            concepts={
                                [
                                    technical
                                        ? ({
                                              ...technical,
                                              title: "Concepto técnico",
                                          } as IConceptExtended)
                                        : null,
                                    business
                                        ? ({
                                              ...business,
                                              title: "Concepto comercial",
                                          } as IConceptExtended)
                                        : null,
                                ].filter(Boolean) as IConceptExtended[]
                            }
                        />
                    </Grid>
                </DialogContent>
            </StyledDialog>
        </Grid>
    );
};
