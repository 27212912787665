import {
    Grid,
    type GridColDef,
    type GridRenderCellParams,
    InfoOutlinedIcon,
    Tooltip,
    enerbitColors,
} from "@enerbit/base";
import type { IIncomingDataItem } from "../../interfaces";
import { useEmissionStore } from "../../store";
import { EmissionTable } from "./EmissionTable";

export const IncomingData = () => {
    const { incomingData } = useEmissionStore();

    if (!incomingData) return null;

    const {
        header,
        generalData,
        mainMeter,
        cabinet,
        communications,
        electricalConductors,
        currentTransformerPhaseR,
        currentTransformerPhaseS,
        currentTransformerPhaseT,
        voltageTransformerPhaseR,
        voltageTransformerPhaseS,
        voltageTransformerPhaseT,
        terminals,
    } = incomingData;

    const columns: GridColDef[] = [
        {
            field: "field",
            headerClassName: "custom-header",
            headerName: "Nombre del campo",
            flex: 2,
            align: "left",
            headerAlign: "left",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
                return params.value as React.ReactNode;
            },
        },
        {
            field: "value",
            headerClassName: "custom-header",
            headerName: "Valor",
            flex: 1,
            align: "left",
            headerAlign: "left",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
                return params.value as React.ReactNode;
            },
        },
        {
            field: "description",
            headerClassName: "custom-header",
            headerName: "",
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableColumnMenu: true,
            width: 20,
            renderCell: (
                params: GridRenderCellParams<string, IIncomingDataItem>,
            ) => {
                return (
                    <Tooltip title={params.value}>
                        <InfoOutlinedIcon
                            fontSize="small"
                            sx={{ color: enerbitColors.neutral[700] }}
                        />
                    </Tooltip>
                );
            },
        },
    ];

    return (
        <Grid container spacing={3}>
            {header.length > 0 && (
                <EmissionTable
                    rows={
                        header.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Encabezado"
                    columns={columns}
                />
            )}
            {generalData.length > 0 && (
                <EmissionTable
                    rows={
                        generalData.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Información general"
                    columns={columns}
                />
            )}
            {mainMeter.length > 0 && (
                <EmissionTable
                    rows={
                        mainMeter.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Medidor principal"
                    columns={columns}
                />
            )}
            {cabinet.length > 0 && (
                <EmissionTable
                    rows={
                        cabinet.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Gabinete"
                    columns={columns}
                />
            )}
            {electricalConductors.length > 0 && (
                <EmissionTable
                    rows={
                        electricalConductors.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Conductores de señal de corriente"
                    columns={columns}
                />
            )}
            {communications.length > 0 && (
                <EmissionTable
                    rows={
                        communications.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Comunicación"
                    columns={columns}
                />
            )}
            {currentTransformerPhaseR.length > 0 && (
                <EmissionTable
                    rows={
                        currentTransformerPhaseR.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Transformador del corriente - Fase R"
                    columns={columns}
                />
            )}
            {currentTransformerPhaseS.length > 0 && (
                <EmissionTable
                    rows={
                        currentTransformerPhaseS.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Transformador del corriente - Fase S"
                    columns={columns}
                />
            )}
            {currentTransformerPhaseT.length > 0 && (
                <EmissionTable
                    rows={
                        currentTransformerPhaseT.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Transformador del corriente - Fase T"
                    columns={columns}
                />
            )}
            {voltageTransformerPhaseR.length > 0 && (
                <EmissionTable
                    rows={
                        voltageTransformerPhaseR.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Transformador del tensión - Fase R"
                    columns={columns}
                />
            )}
            {voltageTransformerPhaseS.length > 0 && (
                <EmissionTable
                    rows={
                        voltageTransformerPhaseS.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Transformador del tensión - Fase S"
                    columns={columns}
                />
            )}
            {voltageTransformerPhaseT.length > 0 && (
                <EmissionTable
                    rows={
                        voltageTransformerPhaseT.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Transformador del tensión - Fase T"
                    columns={columns}
                />
            )}
            {terminals.length > 0 && (
                <EmissionTable
                    rows={
                        terminals.map((item, index) => ({
                            ...item,
                            id: index,
                        })) ?? []
                    }
                    title="Borneras"
                    columns={columns}
                />
            )}
        </Grid>
    );
};
