import { Box, Grid, Skeleton, Stack, enerbitColors } from "@enerbit/base";

export const EmissionSkeleton = () => {
    return (
        <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
                <Skeleton
                    variant="rounded"
                    height={100}
                    sx={{ borderRadius: "12px" }}
                />
            </Grid>
            <Grid item xs={12}>
                <Box
                    sx={{
                        border: `1px solid ${enerbitColors.neutral[300]}`,
                        borderRadius: "12px",
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                spacing={5}
                                sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                    px: 4,
                                    py: 2,
                                }}
                            >
                                <Skeleton
                                    variant="text"
                                    height={50}
                                    width={100}
                                />
                                <Skeleton
                                    variant="text"
                                    height={50}
                                    width={100}
                                />
                                <Skeleton
                                    variant="text"
                                    height={50}
                                    width={150}
                                />
                                <Skeleton
                                    variant="text"
                                    height={50}
                                    width={100}
                                />
                                <Skeleton
                                    variant="rounded"
                                    height={55}
                                    width={150}
                                    sx={{ borderRadius: "12px" }}
                                />
                                <Skeleton
                                    variant="rounded"
                                    height={55}
                                    width={100}
                                    sx={{ borderRadius: "12px" }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Box px={4} pb={2}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Skeleton
                                            variant="text"
                                            height={50}
                                            width={100}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton
                                            variant="rounded"
                                            height={55}
                                            sx={{ borderRadius: "12px" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton
                                            variant="rounded"
                                            height={55}
                                            sx={{ borderRadius: "12px" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton
                                            variant="rounded"
                                            height={55}
                                            sx={{ borderRadius: "12px" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton
                                            variant="rounded"
                                            height={55}
                                            sx={{ borderRadius: "12px" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton
                                            variant="rounded"
                                            height={55}
                                            sx={{ borderRadius: "12px" }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};
