import { api } from "@enerbit/base";
import { create } from "zustand";
import type { IPendingReviewStore, IRespPendingReview } from "../interfaces";

const initialState = {
    loadingPendingReview: false,
    errorMessage: "",
    listDiagnostics: [],
    page: 0,
    size: 30,
    filter: "",
};

export const usePendingReviewStore = create<IPendingReviewStore>()((set) => ({
    ...initialState,
    getPendingDiagnostics: async (page, size, _filter, diagnosticType) => {
        try {
            set({
                loadingPendingReview: true,
                listDiagnostics: [],
                errorMessage: "",
            });
            const { data } = await api.get<IRespPendingReview[]>(
                `/frontier-initial-diagnostic/pending-diagnostics?diagnostic_type=${diagnosticType}&page=${page}&size=${size}`,
            );
            set({ loadingPendingReview: false, listDiagnostics: data });
        } catch (_error) {
            set({
                loadingPendingReview: false,
                errorMessage:
                    "No se pudo encontrar los diagnósticos pendientes.",
            });
        }
    },
}));
