import {
    Box,
    Button,
    CircularProgress,
    DialogContent,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import { type FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { StyledDialog, StyledDialogTitle } from "../../../common";
import type { IIncomingDataItemInverter } from "../../../interfaces";
import { useEmissionStoreSolar } from "../../../store/emission-solar";

interface Props {
    open: boolean;
    handleClose: () => void;
}

export const AddInverterSolar: FC<Props> = ({ open, handleClose }) => {
    const {
        addInverters,
        listBrands,
        getModels,
        listModels,
        isLoadingBrands,
        isLoadingModels,
        inverterEditObject,
        updateInverters,
    } = useEmissionStoreSolar();
    const validationSolarInverters = z.object({
        inverter_brand: z.string().min(1, "Este campo es obligatorio."),
        inverter_model: z.string().min(1, "Este campo es obligatorio."),
        inverter_serial: z.string().min(1, "Este campo es obligatorio."),
    });

    const { control, watch, getValues, trigger, setValue } =
        useForm<IIncomingDataItemInverter>({
            resolver: zodResolver(validationSolarInverters),
            defaultValues: {
                inverter_brand: inverterEditObject?.inverter_brand ?? "",
                inverter_brand_new:
                    inverterEditObject?.inverter_brand_new ?? "",
                inverter_model_new:
                    inverterEditObject?.inverter_model_new ?? "",
                inverter_model: inverterEditObject?.inverter_model ?? "",
                inverter_serial: inverterEditObject?.inverter_serial ?? "",
            },
            mode: "onChange",
        });

    const handleAddInverter = async () => {
        if (await trigger()) {
            addInverters({
                ...getValues(),
            });
            handleClose();
        }
    };

    const handleEditInverter = () => {
        updateInverters({
            ...getValues(),
            id: inverterEditObject?.id,
        });
        handleClose();
    };

    const getModelsByBrand = async () => {
        const { inverter_brand } = getValues();
        const brandId = listBrands.find(
            (brand) => brand.brand === inverter_brand,
        )?.id;
        if (brandId) {
            await getModels(brandId);
        }
    };

    useEffect(() => {
        if (watch("inverter_brand") != "Otro") {
            setValue("inverter_brand_new", "");
        }
        if (watch("inverter_model") != "Otro") {
            setValue("inverter_model_new", "");
        }
    }, [watch("inverter_brand"), watch("inverter_model")]);

    useEffect(() => {
        getModelsByBrand();
    }, [watch("inverter_brand")]);

    return (
        <StyledDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
        >
            <StyledDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                {inverterEditObject ? "Editar" : "Ingresar"} un nuevo registro
            </StyledDialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputLabel>Marca inversor</InputLabel>
                            <FormControl fullWidth>
                                <Controller
                                    name="inverter_brand"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            disabled={isLoadingBrands}
                                            displayEmpty
                                            sx={{
                                                "& fieldset": {
                                                    borderRadius: "14px",
                                                },
                                            }}
                                        >
                                            {listBrands.map((item, index) => (
                                                <MenuItem
                                                    value={item.brand}
                                                    key={index.toString()}
                                                >
                                                    {item.brand}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {watch("inverter_brand") === "Otro" && (
                            <Grid item xs={12}>
                                <InputLabel>
                                    Inserte nueva marca aquí!
                                </InputLabel>
                                <FormControl fullWidth>
                                    <Controller
                                        name="inverter_brand_new"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                sx={{
                                                    "& fieldset": {
                                                        borderRadius: "14px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                        {watch("inverter_brand") && (
                            <Grid item xs={12}>
                                <InputLabel>Modelo inversor</InputLabel>
                                <FormControl fullWidth>
                                    <Controller
                                        name="inverter_model"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                displayEmpty
                                                disabled={isLoadingModels}
                                                sx={{
                                                    "& fieldset": {
                                                        borderRadius: "14px",
                                                    },
                                                }}
                                                endAdornment={
                                                    isLoadingModels ? (
                                                        <InputAdornment position="start">
                                                            <CircularProgress
                                                                size={20}
                                                                thickness={6}
                                                            />
                                                        </InputAdornment>
                                                    ) : null
                                                }
                                            >
                                                {listModels.map(
                                                    (item, index) => (
                                                        <MenuItem
                                                            value={item.model}
                                                            key={index.toString()}
                                                        >
                                                            {item.model}
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                        {watch("inverter_model") === "Otro" && (
                            <Grid item xs={12}>
                                <InputLabel>
                                    Inserte nuevo modelo aquí!
                                </InputLabel>
                                <FormControl fullWidth>
                                    <Controller
                                        name="inverter_model_new"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                sx={{
                                                    "& fieldset": {
                                                        borderRadius: "14px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                        {watch("inverter_model") && (
                            <Grid item xs={12}>
                                <InputLabel>Serial</InputLabel>
                                <FormControl fullWidth>
                                    <Controller
                                        name="inverter_serial"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                sx={{
                                                    "& fieldset": {
                                                        borderRadius: "14px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        {
                                            inverterEditObject
                                                ? handleEditInverter()
                                                : handleAddInverter();
                                        }
                                    }}
                                >
                                    {inverterEditObject
                                        ? "Actualizar"
                                        : "Agregar"}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </StyledDialog>
    );
};
