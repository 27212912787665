import {
    Box,
    Grid,
    type GridColDef,
    Typography,
    enerbitColors,
    esES,
} from "@enerbit/base";
import type { FC } from "react";
import { NoRowsOverlay, StyledDataGrid } from "../../common";
import type {
    IConceptItem,
    IDocumentaryComplianceItem,
    IIncomingDataItem,
    IIncomingDataItemInverter,
    IMeasurementSystemItem,
} from "../../interfaces";

interface Props {
    rows:
        | IIncomingDataItem[]
        | IMeasurementSystemItem[]
        | IDocumentaryComplianceItem[]
        | IConceptItem[]
        | IIncomingDataItemInverter[];
    title?: string;
    columns: GridColDef[];
}

export const EmissionTable: FC<Props> = ({ columns, rows, title }) => {
    return (
        <>
            {title && (
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            color: enerbitColors.primary.main,
                            fontWeight: "bold",
                        }}
                    >
                        {title}
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <Box
                    sx={{
                        width: "100%",
                        "& .custom-header": {
                            backgroundColor: "#EFE9FB",
                            "&:first-of-type": {
                                borderTopLeftRadius: "12px",
                                borderBottomLeftRadius: "12px",
                            },
                            "&:last-child": {
                                borderTopRightRadius: "12px",
                                borderBottomRightRadius: "12px",
                            },
                        },
                    }}
                >
                    <StyledDataGrid
                        sx={{
                            "& .MuiDataGrid-virtualScrollerContent": {
                                height:
                                    rows.length > 0
                                        ? "auto!important"
                                        : "50px!important",
                            },
                            "& .MuiDataGrid-row": {
                                minHeight: "52px!important",
                                py: 2,
                            },
                        }}
                        getRowHeight={() => "auto"}
                        disableVirtualization
                        columns={columns}
                        rows={rows}
                        pageSize={100}
                        autoHeight
                        hideFooter
                        paginationMode="client"
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        components={{ NoRowsOverlay }}
                    />
                </Box>
            </Grid>
        </>
    );
};
