import {
    CancelIcon,
    CheckCircleIcon,
    Grid,
    type GridColDef,
    type GridRenderCellParams,
    Tooltip,
} from "@enerbit/base";
import { EmissionTable } from ".";
import type { IDocumentaryComplianceItem } from "../../interfaces";
import { useEmissionStore } from "../../store";

const columns: GridColDef[] = [
    {
        field: "name",
        headerClassName: "custom-header",
        headerName: "Nombre del documento",
        flex: 1,
        align: "left",
        headerAlign: "left",
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "status",
        headerClassName: "custom-header",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        width: 20,
        renderCell: (
            params: GridRenderCellParams<string, IDocumentaryComplianceItem>,
        ) => {
            return (
                <Tooltip title={params.value ? "Cumple" : "No cumple"}>
                    {params.value ? (
                        <CheckCircleIcon fontSize="small" color="success" />
                    ) : (
                        <CancelIcon fontSize="small" color="error" />
                    )}
                </Tooltip>
            );
        },
    },
];

export const DocumentaryCompliance = () => {
    const documentaryCompliance = useEmissionStore(
        (state) => state.documentaryCompliance,
    );

    return (
        <Grid container spacing={3}>
            {documentaryCompliance && documentaryCompliance.length > 0 && (
                <EmissionTable
                    rows={documentaryCompliance.map((item, index) => ({
                        ...item,
                        id: index,
                    }))}
                    columns={columns}
                />
            )}
        </Grid>
    );
};
