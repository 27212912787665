import { Box, Grid, Typography } from "@enerbit/base";
import type { SyntheticEvent } from "react";
import { StyledTab, StyledTabs, TabPanel } from "../common";
import { Diagnostics } from "../components/diagnostics";
import { Emission } from "../components/emission";
import { EmissionSolar } from "../components/emission/solar/EmissionSolar";
import { PendingReview } from "../components/pending-review";
import { OrderTypes } from "../enums/solar/ordersType";
import {
    useEmissionStore,
    useTableStore,
    useViabilityDiagnosticStore,
} from "../store";

const a11yProps = (index: number) => {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
};

export const ViabilityDiagnostic = () => {
    const { resetTable, diagnosticType } = useTableStore();

    const { valueTab, setValueTab } = useViabilityDiagnosticStore();

    const { clearError } = useEmissionStore();

    const handleChange = (_event: SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
        clearError();
        resetTable();
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography
                    color="primary"
                    sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
                >
                    Diagnóstico de viabilidad
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <StyledTabs
                        value={valueTab}
                        onChange={handleChange}
                        aria-label="tabs viability diagnostic"
                    >
                        <StyledTab
                            label="Consulta y emisión"
                            {...a11yProps(0)}
                        />
                        <StyledTab label="Diagnósticos" {...a11yProps(1)} />
                        <StyledTab
                            label="Pendiente por revisar"
                            {...a11yProps(2)}
                        />
                    </StyledTabs>
                </Box>
                <TabPanel value={valueTab} index={0}>
                    {diagnosticType === OrderTypes.conventional && <Emission />}
                    {diagnosticType === OrderTypes.solar && <EmissionSolar />}
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                    <Diagnostics />
                </TabPanel>
                <TabPanel value={valueTab} index={2}>
                    <PendingReview />
                </TabPanel>
            </Grid>
        </Grid>
    );
};
