import { z } from "zod";

const diagnosticSchema = z.object({
    measurement_type: z.string().min(1, "Este campo es obligatorio."),
    comunication: z.string().min(1, "Este campo es obligatorio."),
    opening: z.string().min(1, "Este campo es obligatorio."),
    main_meter: z.string().min(1, "Este campo es obligatorio."),
    optional_meter: z.string().min(1, "Este campo es obligatorio."),
    test_block: z.string().min(1, "Este campo es obligatorio."),
    current_transformer: z.string().nullable(),
    voltage_transformer: z.string().nullable(),
    box: z.string().min(1, "Este campo es obligatorio."),
    control_wire: z.string().min(1, "Este campo es obligatorio."),
    other: z.string().min(1, "Este campo es obligatorio."),
    comment_or: z.string().min(1, "Este campo es obligatorio.").nullable(),
    observation_work_order: z
        .string()
        .min(1, "Este campo es obligatorio.")
        .nullable(),
    comment_bussiness: z
        .string()
        .min(1, "Este campo es obligatorio.")
        .nullable(),
    comment_quotation: z
        .string()
        .min(1, "Este campo es obligatorio.")
        .nullable(),
    status: z.string().min(1, "Este campo es obligatorio."),
    reasons_update: z.string().optional(),
    of_type: z.string(),
});

export const PatchEmitDiagnosticSchema = z.object({
    diagnostic: diagnosticSchema,
    materials_and_labor: z.object({}),
    emited_by: z
        .object({
            name: z.string(),
            email: z.string(),
            operator_id: z.string(),
        })
        .nullable(),
});
