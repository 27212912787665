import { Grid, Typography, enerbitColors } from "@enerbit/base";
import type { FC } from "react";
import { InstallationItem } from ".";
import type { IInstallationItem } from "../../interfaces";

interface Props {
    category: string;
    installations: IInstallationItem[];
}

export const InstallationCostContainer: FC<Props> = ({
    category,
    installations,
}) => {
    return (
        <>
            <Grid item xs={12}>
                <Typography
                    fontWeight={"bold"}
                    fontSize={"18px"}
                    color={enerbitColors.neutral[700]}
                >
                    {category}
                </Typography>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                {installations.map((installation, index) => {
                    return (
                        <InstallationItem
                            key={index.toString()}
                            installation={installation}
                            size={installations.length}
                        />
                    );
                })}
            </Grid>
        </>
    );
};
