import { Grid, Typography, enerbitColors } from "@enerbit/base";
import { type FC, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { PhotoCard } from ".";
import type { IPictureArray } from "../../interfaces";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/counter.css";

interface Props {
    name: string;
    data?: IPictureArray[] | null;
}

export const PhotographsSection: FC<Props> = ({ name, data }) => {
    const [index, setIndex] = useState(-1);

    return (
        <>
            {data && data?.length > 0 && (
                <Grid item container xs={12} spacing={3}>
                    <Grid item xs={12}>
                        <Typography
                            fontWeight={"bold"}
                            color={enerbitColors.primary.main}
                        >
                            {name}
                        </Typography>
                    </Grid>
                    {data.map((item, index) => {
                        return (
                            <PhotoCard
                                key={index.toString()}
                                image={item}
                                index={index}
                                setIndex={setIndex}
                            />
                        );
                    })}
                    <Lightbox
                        plugins={[Captions, Counter, Zoom]}
                        index={index}
                        slides={data.map(({ image_url, image_title }) => ({
                            src: image_url ?? "",
                            description: image_title,
                        }))}
                        open={index >= 0}
                        close={() => setIndex(-1)}
                    />
                </Grid>
            )}
        </>
    );
};
