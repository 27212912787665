import { Box, Typography } from "@enerbit/base";
import type { FC } from "react";
import { formatPrice } from "../../helpers";

interface Props {
    title: string;
    value: number;
}

export const CostItem: FC<Props> = ({ title, value }) => {
    return (
        <Box
            sx={{
                display: "flex",
                gap: 2,
                justifyContent: "space-between",
                width: "100%",
            }}
        >
            <Typography>{title}</Typography>
            <Typography fontWeight={"bold"}>{formatPrice(value)}</Typography>
        </Box>
    );
};
