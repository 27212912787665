import type {
    IIncomingDataItem,
    IIncomingDataItemInverter,
    ILeadItem,
} from "../../interfaces";
import type { IInputDataFormDataSolar } from "../../interfaces/solar/IRespOutputDataSolar";

export const generateDataTelemetrySolar = (
    _lead: ILeadItem,
    formData?: IInputDataFormDataSolar,
): IIncomingDataItem[] => {
    const items = [
        {
            field: "Tipo de salida de datos",
            value: formData?.data_telemetry?.type_output_data ?? "",
            description: "Tipo de salida de datos",
        },
    ];
    const filteredItems = items.filter((item) => item.value !== "");

    return filteredItems;
};

export const generateInverters = (
    formData: IInputDataFormDataSolar,
): IIncomingDataItemInverter[] | null => {
    const inverters =
        formData?.sfv_information?.inverters.flatMap((item) => {
            return [
                {
                    inverter_brand:
                        item.inverter.inverter_brand_new === ""
                            ? item.inverter.inverter_brand
                            : item.inverter.inverter_brand_new ?? "",
                    inverter_model:
                        item.inverter.inverter_model_new === ""
                            ? item.inverter.inverter_model
                            : item.inverter.inverter_model_new ?? "",
                    inverter_serial: item.inverter.inverter_serial ?? "",
                },
            ];
        }) ?? null;

    return inverters;
};

export const generateAccessInstallationSolar = (
    _lead: ILeadItem,
    formData?: IInputDataFormDataSolar,
): IIncomingDataItem[] => {
    const items = [
        {
            field: "¿Acceso lugar de instalación inversor?",
            value: formData?.access_installation?.access_inverter_installation
                ? "Sí"
                : "No",
            description: "¿Acceso lugar de instalación inversor?",
        },
        {
            field: "Observaciones al lugar acceso de instalación inversor",
            value:
                formData?.access_installation
                    ?.access_inverter_installation_observations ?? "",
            description:
                "Observaciones al lugar acceso de instalación inversor",
        },
        {
            field: "¿Acceso lugar de instalación medidor?",
            value: formData?.access_installation?.access_meter_installation
                ? "Sí"
                : "No",
            description: "¿Acceso lugar de instalación medidor?",
        },
        {
            field: "Observaciones al lugar acceso de instalación medidor",
            value:
                formData?.access_installation
                    ?.access_meter_installation_observations ?? "",
            description: "Observaciones al lugar acceso de instalación medidor",
        },
        {
            field: "Desnergización red eléctrica",
            value:
                formData?.access_installation?.de_energization_energy_network ??
                "",
            description: "Desnergización red eléctrica",
        },
        {
            field: "Desnergización del sistema solar",
            value:
                formData?.access_installation
                    ?.de_energization_energy_inverter ?? "",
            description: "Desnergización del sistema solar",
        },
    ];
    const filteredItems = items.filter((item) => item.value !== "");

    return filteredItems;
};

export const generateSfvInformationSolar = (
    _lead: ILeadItem,
    formData?: IInputDataFormDataSolar,
): IIncomingDataItem[] => {
    const items = [
        {
            field: "Número de inversores",
            value: formData?.sfv_information?.inverter_quantity ?? "",
            description: "Número de inversores",
        },
    ];

    return items;
};

export const generateObservations = (
    formData?: IInputDataFormDataSolar,
): IIncomingDataItem[] => {
    const items = [
        {
            field: "¿Este sistema de medida corresponde a una zona común?",
            value: formData?.observations?.is_common_measurement_system
                ? "Sí"
                : "No",
            description:
                "¿Este sistema de medida corresponde a una zona común?",
        },
        {
            field: "¿Está servicio es utilizado con algún fin comercial?",
            value: formData?.observations?.common_measurement_system ?? "",
            description: "¿Está servicio es utilizado con algún fin comercial?",
        },
        {
            field: "¿Requiere apertura para la siguiente visita de instalación o normalización?",
            value: formData?.observations?.is_next_visit_open ? "Sí" : "No",
            description:
                "¿Requiere apertura para la siguiente visita de instalación o normalización?",
        },
        {
            field: "¿Cuánto tiempo se estima que el cliente quedaría sin energía?",
            value: formData?.observations?.next_visit_open ?? "",
            description:
                "¿Cuánto tiempo se estima que el cliente quedaría sin energía?",
        },
        {
            field: "¿Se requiere equipo de trabajo o herramienta especial para la siguiente visita de instalación o normalización?",
            value: formData?.observations?.is_special_equipment_need
                ? "Sí"
                : "No",
            description:
                "¿Se requiere equipo de trabajo o herramienta especial para la siguiente visita de instalación o normalización?",
        },
        {
            field: "Especificar su requerimiento",
            value: formData?.observations?.special_equipment_need ?? "",
            description: "Especificar su requerimiento",
        },
        {
            field: "Mecanismos de suspención",
            value: formData?.observations?.for_us.suspension_mechanism ?? "No",
            description: "Mecanismos de suspención",
        },
    ];

    const filteredItems = items.filter((item) => item.value !== "");

    return filteredItems;
};
